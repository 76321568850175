import { OPVS_URL } from "../../config";

export const departments = [
  {
    title: "Administration Department",
    description:
      "The department look after administrative and development issues of whole hospital.",
    keyPersons: [
      {
        name: "Dr. Fazle Maula ",
        designation: "Executive Director/Director General HC& SI",
        image: "",
      },
      {
        name: "Dr Mustafa Alam ",
        designation: "Director Administration",
        image: "",
      },
      {
        name: "Dr Tariq Mehmood ",
        designation: "Deputy Director Medical I",
        image: "",
      },
      {
        name: "Dr Irshad Jokhio ",
        designation: "Deputy Director Medical II",
        image: "",
      },
      {
        name: " Miss Ruby Dilshad",
        designation: "Assistant Director ",
        image: "",
      },
      {
        name: " Matloob Bhatti ",
        designation: "Accounts Officer ",
        image: "",
      },
      {
        name: "Vacant",
        designation: "Director P & D",
        image: "",
      },
    ],
    icon: "admin.svg",
  },
  {
    title: "Anesthesia / OT Department",
    description:
      "The anesthsia look after OT & ICU of the hospital.We have four operation theatres and two labour rooms equipped with latest equipment. Central air conditioning with laminar air flow minimizes infection in the operation theatres which are equipped sdwith remote controlled and fully motorized operation tables capable of supporting all type of surgical procedures. Servo Ventilator system used as anaesthesia equipment, programmable electro surgical cautery machines, operating microscopes of ENT and Eye Surgeries further help the surgeon optimize their skill. The theatres are also equipped with ECG monitoring, resuscitation trolley and defibrillator to cater for emergencies.",
    keyPersons: [
      {
        name: "Vacant",
        designation: "Anesthetist",
        image: "",
      },
      {
        name: "Syed Shabbir Hussain",
        designation: "Assistant Anesthetist",
        image: "",
      },
    ],
    icon: "ot.svg",
  },
  {
    title: "Bariatric Surgery Department",
    description:
      "Department of Bariatric Surgery Surgery is newly added department in Capital Hospital.",
    keyPersons: [
      {
        name: "Dr. Sartaj Ali",
        designation: "Bariatric Surgeon",
        image: "",
      },
    ],
    icon: "surgery.svg",
  },
  {
    title: "Bio Medical Engineering Department",
    description:
      "The department is responsible to supervise and carryout installations, repair, service and maintenance of electro-medical and electronic equipment of the Capital Hospital. In addition, it also carries out preventive maintenance of medical equipment, design and develop modifications as per individual requirement of the equipment / insruments, inspection of instruments at the time of delivery and maintain repair/ service history of eletro medical equipment.",
    keyPersons: [
      {
        name: "Qazi Muhammad Saleem",
        designation: "Director Biomedical",
        image: "",
      },
    ],
    icon: "bio.svg",
  },
  {
    title: "Cardiac Surgery Department",
    description:
      "Department of Cardiac surgery is headed by Consultant cardiac surgeon Dr. Mahrukh Zahoor MBBS, MCPS (General Surgery), FCPS (Cardiac Surgery) from AFIC affiliated with Royal College of Edinburgh. Dr. Mahrukh specializes in Coronary Artery Bypass surgery, Pericardial Heart diseases and Valvular Heart surgeries and has performed and assisted in more than 8,000 open heart surgeries since 2003. She has post FCPS hands on international training in complex cardiac surgeries like Aortic Root replacements, Minimally Invasive cardiac surgery and video assisted thoracic surgery procedure from National Institute of Health Singapore conducted by Royal College of Edinburgh in 2016. She has attended various national and international seminars and conferences on the subject with 04 national and 03 international research publications between 2007 and 2020. Dr. Mahrukh has joined Capital Hospital CDA in July 2022 and holds additional positions of Chairman infection control committee and Member academic council PGMI Capital Hospital. Cardiac Surgery department was functionalized and pioneer open heart surgeries at Capital Hospital were done within 03 months and 02 closed heart cases with 100% success rate. Cardiac surgery department is now fully equipped with 03 bedded Cardiac surgery ICU, Cardiac operation theatre and 06 bedded ward. Dr. Mahrukh is assisted by Dr. Samiullah.",
    keyPersons: [
      {
        name: "Dr. Mahrukh Zahoor",
        designation: "Consultant Cardiac Surgeon",
        image: "",
      },
    ],
    icon: "cardiac.svg",
  },
  {
    title: "Dental Surgery Department",
    description:
      "Dental department of the Capital Hospital is one of the best dental setups in Islamabad. It is headed by Dr. Niaz Ahmed, who is associated by 03 dentists Dr. Nausheen Asif, Dr. Masooma Syed and Dr. Misbah Fatima. The department is well equipped with latest instruments like ultrasonic scalers, digital periapical x-ray unit, digital OPG machine, light cure units and particularly 04 cycle sterilizer to ensure the sterilization of instruments. All general dental procedures like extractions, filling, root canal treatments, prophylactic procedures and surgical procedures like removal of impacted teeth, apicoectomies and gingivectomies are performed in the department.",
    keyPersons: [
      {
        name: "Vacant",
        designation: "Dental Surgeon",
        image: "",
      },
    ],
    icon: "dental.svg",
  },
  {
    title: "Dermatology Department",
    description:
      "Department of Dermatology, Capital hospital Islamabad is a well reputed dermatology unit in the capital territory. It is headed by Dr Haider Ali Shah & is supervised by Dr Uzma Ali. Medical Officers include Dr. Sultan Muhammad and Dr. Muneeza Hassan. It is the best indoor facility in public sector in Islamabad & the suburbs with separate male & female general wards, officer's beds & ICU facility. All acute, rare & serious cases of dermatology like Psoriasis, pemphigus, epidermolysis bullosa, carbuncles, SJS & TEN, etc are admitted & being managed by the team of qualified and well trained Dermatologists. The department is equipped with Nd-YAG laser for non-cosmetic indications like Vascular Navei, pigmented naevi, tattoos & telangectasias. In addition to the above, department of dermatology has multiple phototherapy units (largest in the capital territory & suburbs). It is also the only public hospital providing cryotherapy facility free of cost to both entitled & non-entitled patients. Department of dermatology has its own minor OT where procedures such as skin biopsies, excisions, cautery, PRP and several other dermatological procedures are performed safely & hygienically. The department is a recognized department for post graduate training by CPSP since 2011 onwards with several trainees has already successfully completed both FCPS & MCPS training here, The Department is also highly reputed for House job training.",
    keyPersons: [
      {
        name: "Dr. Haider Ali Shah",
        designation: "Dermatologist",
        image: "",
      },
      {
        name: "Dr. Uzma",
        designation: "Assoc. Dermatologist",
        image: "",
      },
    ],
    icon: "dermatology.svg",
  },
  {
    title: "ENT Department",
    description:
      "Department of Otorhinolaryngology, Capital Hospital is headed by renowned ENT surgeons of the country and has the facilities of Conventional ENT as well as micro surgery. It is headed by Dr. Ghulam Saqulain, M.B.B.S, D.L.O, F.C.P.S, PGD (Project Management), Supervisor F.C.P.S., M.Phil (Hearing Sciences) and assisted by Dr. Jawwad Ahmad, M.B.B.S, M.C.P.S, F.C.P.S., Associate ENT Surgeon, and Supervisor F.C.P.S. Medical Officers include Dr. Naheeda Khawar and Dr. Farooq Khan. It is the only public-sector hospital in Islamabad Rawalpindi region to have a cochlear implant program. Other services offered by the department include consultation, examination and treatment facility in outpatients for three days a week. Its outpatientsare equipped with microscopes and modern diagnostic and treatment Unitas well as rigid ENT endoscopy as well as flexible nasopharyngoscopy. Surgical Procedures performed on the Ear, Nose, Throat and head and neck in the department include all conventional ear, nose and throat surgical procedures as well as cochlear implantation and cleft surgeries. The department maintains a very low mortality and morbidity of patients treated here.Its outdoors is also equipped with audiology facilities including pure tone audiometry. The department is recognized for house job as well as post graduate training for FCPS and MS ENT. The departments head has in his credit a number of research articles and case reports published in peer reviewed national and international journals. A rare case report ?Haemangioma of the Hyoid Bone: a rare case report published in the Turkish archives of otolaryngology is the first reported case in the world literature.",
    keyPersons: [
      {
        name: "Dr. Ghulam Saqulain",
        designation: "Consultant ENT Surgeon",
        image: "",
      },
      {
        name: "Dr. Jawwad Ahmad",
        designation: "ENT Surgeon",
        image: "",
      },
    ],
    icon: "ent.svg",
  },
  {
    title: "Emergency Department",
    description:
      "Emergency (ER) department of the Capital Hospital is providing emergency services to the CDA employees and general public of Islamabad / Rawalpindi. The department is dealing with the emergency cases like chest pain, acute asthma, DKA, acute gastroenteritis along with surgical emergencies, renal colic and trauma cases. These cases are attended by CMO's and doctors of the concerned departments. Opinion of senior consultants is also obtained in the ER. Serious patients are retained in the ER and after their stabilization they are shifted / admitted to the concerned ward. Patients with minor ailment are discharged from ER after treatment. Dr. Syed Iqtidar Ali (MBBS, MS) is in-charge of the department. He is assisted by Dr. Khalid Jamil, Dr. Munawar Shah and Dr. Riffat Hamid, Dr. Muhammad Farooq. ER department is equipped with 21 beds. There is central oxygen and suction system with outlet of each bed. There is a POP room for the treatment of the fracture cases and a dressing room for antiseptic dressing.",
    keyPersons: [
      {
        name: "Dr Syed Iqtidar Ali Raza",
        designation: "Incharge CMO",
        image: "",
      },
    ],
    icon: "emergency.svg",
  },
  {
    title: "Endocrinology Department",
    description:
      "Department of Endocrinology, Recently this new department added in capital hospital with a very highly qualified consultants. This department diagnosis, treat, and manage diabetes, endroicrinol and hormonal disorder patients. Diabetes Mellitus patients are mainly catered in this department.",
    keyPersons: [
      {
        name: "Dr.Sarwar Malik",
        designation: "Endocrinologist",
        image: "",
      },
    ],
    icon: "endo.svg",
  },
  {
    title: "OPTHALMOLOGY / EYE Department",
    description:
      "Department of Ophthalmology CDA Capital hospital is a reasonably equipped with latest diagnostic and treatment facilities. The Special Ophthalmic examination provided by the Department includes visual field analysis performed with modern computerized visual field Analyzer. The Special Ophthalmic procedure provided by the Department includes YAG laser capsulotomy. The department is also well equipped with latest phacoemusification facilities along with routine surgical procedures. It has 10 bedded male & female ophthalmology ward along with a side room for emergency procedures. There are two OT days, three OPD days and a day is fixed for glaucoma patients. The department is headed by Dr. Waheed Afzal, Dr Waqar Ahmed, Associate Eye Surgeon and Dr. Rukhsana Tabbasum (Registrar). Medical Officers include Dr. Zahid Nazir and Dr. Nadia Zehra. Two house surgeons are being sanctioned for training.",
    keyPersons: [
      {
        name: "Dr. Waqar Ahmed",
        designation: "Surgeon (Eye)",
        image: "",
      },
    ],
    icon: "eye.svg",
  },
  {
    title: "General Surgery Department",
    description:
      "The Surgery department is headed by Dr Muhammad Naeem Taj MBBS, FCPS (Pak), FRCS (UK, Glasgow), FACS (USA), Gold Medalist in field of surgery for the year 2012, Examiner of CPSP and MS Exams, Twice Guinness World record Holder, General secretary Society of Surgeons. Dr Naeem Taj has registered the name of Capital Hospital in Guinness book of world record for removing the largest gall bladder through smallest incision laparoscopic ally. He is assisted by Dr. Tanseer Asghar, Dr. Ana Jalil and Dr. Israr Ahmed. The Surgery department consists of 50 beds and conducting all sorts of General, Laparoscopic and Bariatric surgeries procedures on regular basis. The department provides care to outpatients and inpatients with conditions requiring surgery. Besides, the department serves as a superconsultancy body for patients requiring bariatric, breast cancer, biliary tract, pancreas and alimentary canal surgery from all over the country. Patients with gall stones breast disease and injuries to the loco motor tract make up a major proportion of those treated in the department. The department has also long been involved in the advance Laparoscopic treatment of all abdominal conditions. The Surgery department has conducted various international and national conferences in capital Hospital on Bariatric, Laparoscopic, Hernia, Breast cancer, Triage, ATLS, BLS, primary and advance surgical skills. The Surgery department has established breast cancer clinic and bariatric Surgery Clinic for obese patients besides taking care of general surgery patients. The Surgery department has performed maximum number of operations every year in capital Hospital for the last one decade and more than ten thousand surgeries performed in last ten years with excellent results. The Surgery department has trained many surgeons for postgraduate training and is recognized by CPSP and PMDC for postgraduate training.",
    keyPersons: [
      {
        name: "Dr. Muhammad Naeem Taj",
        designation: "Consultant General Surgeon",
        image: "",
      },
      {
        name: "Dr. Tanseer Asghar",
        designation: "General Surgeon",
        image: "",
      },
      {
        name: "Dr. Israr Ahmed",
        designation: "General Surgeon",
        image: "",
      },
      {
        name: "Dr. Ana Jalil",
        designation: "Associate Surgeon",
        image: "",
      },
    ],
    icon: "general.svg",
  },
  {
    title: "Gynaecology & obstetrics Department",
    description:
      "The department is headed by associate gynecologist Dr. Sheeba Noureen 04 Medical Officers and 04 House Officers. It comprises of both outpatient and inpatient departments. There are 26 beds in the indoor with 02 fully functional labor rooms. The OPD comprises of antenatal clinic, gynecological clinic, infertility clinic and contraception advice. The procedures done in OPD are: PAP smear, insertion and removal of IUCD, ultrasound examination of obstetrics and gynecological patients and hysterosalpingography. Both emergency and elective surgeries are done for obstetric and gynecological patients with minimal of morbidity and mortality.",
    keyPersons: [
      {
        name: "Dr. Sheeba Noreen",
        designation: "Gynaecologist",
        image: "",
      },
    ],
    icon: "gynae.svg",
  },
  {
    title: "HMIS Departments",
    description:
      "Hospital Management Information System was inaugurated in December 2008. This department is playing a vital role to improve patient services through better management and efficient use of available resources. HMIS department has a data center that comprises of HP/DELL servers, core switches and edge switches. The 212 nodes network is managed by the technical staff of HMIS department.<br/>Mr. Ahsan Raza,Mr. Haleem Hussain Shah, Engr. Arif Saleem, Mr. Asad Munir, Mr. Asif ur Rehman, Mr. Fazal Hussain,  Muhammad Ashfaq and Mr. Saeedullah are part of the team.",
    keyPersons: [
      {
        name: "Syed Ahsan Raza Naqvi",
        designation: "Analyst Programmer",
        image: "",
      },
      {
        name: "Haleem Hussain Shah",
        designation: "Network Administrator",
        image: "",
      },
    ],
    icon: "hmis.svg",
  },
  {
    title: "Kitchen Department",
    description:
      "The Hospital utilizes the services of an experience dietician (Scientific Food Officer) who is responsible for maintaining the quality  and quantity of food items, both raw and cooked. Diet is provided to the patients keeping in veiew their dianosis and caloric needs.. The Scientific Food Officer also ensures proper hygienic conditions in the kitchen.",
    keyPersons: [
      {
        name: "Miss Ambreen",
        designation: "SFO(Scentific Food Officer)",
        image: "",
      },
    ],
    icon: "kitchen.svg",
  },
  {
    title: "Maxilofacial Department",
    description:
      "Department of Oral & Maxillofacial Surgery is newly added department in Capital Hospital. The department manage deals with the diagnosis and treatment of a broad range of disorders affecting the facial structure (skeleton) including jaws and the oral cavity.",
    keyPersons: [
      {
        name: "Dr. Muhammad Jamal",
        designation: "Maxillofacial Surgeon",
        image: "",
      },
    ],
    icon: "maxill.svg",
  },
  {
    title: "Medicine Department",
    description:
      "Department of Medicine in Capital Hospital is the largest medical unit in twin cities of Rawalpindi and Islamabad. It is headed by Dr. Aziz un Nisa. It is a seventy bedded unit with Male, Female, Officers Medical Wards, Intensive Care Unit and Dengue Wards. Procedures such as Upper GI Endoscopies, Lower GI Endoscopies, Sclerotherapies, Endoscopic Biopsies Liver Biopsies, Guided Liver Aspirations, Echocardiographies, Exercise Tolerence Tests, Holters Monitoring, Spirometry, Abdominopelvic Ultrasounds, Ultrasound Guided Diagnostic and Therapeutic procedures are done in the Medical Department. There are specialists like Dr Farhan Ali (Medical Specialist) and Dr Rashid Iqbal (Registrar). Medical Officers include Dr Asif Tauseef, Dr. Shaheen Afzal, Dr Shafiq Farooqi, Dr Shumaila Zainab, Dr. Arifa Gulab and Dr Javaid Iqbal. All the doctors work unitedly for the purpose of a common goal of good patient care.",
    keyPersons: [
      {
        name: "Dr. Aziz-un-Nisa",
        designation: "Physician (Gastroentrologist)",
        image: "",
      },
      {
        name: "Dr. Farhan Ali",
        designation: "Physician",
        image: "",
      },
    ],
    icon: "medical.svg",
  },
  {
    title: "Nephrology Department",
    description:
      "Nephrology Services in the Capital Hospital were initiated in 2007 with one dialysis center and a Nephrology department. The department is headed by Dr Zulfiqar Ali (FCPS), Nephrologist, assisted by Dr. Murtaza Hussain, nurses and technicians.",
    keyPersons: [
      {
        name: "Dr. Zulfiqar Ali",
        designation: "Nephrologist",
        image: "",
      },
    ],
    icon: "nephrology.svg",
  },
  {
    title: "Neuro Surgery Department",
    description:
      "The Neurosurgery department is headed by Dr Alamgir Khan MBBS, FCPS, Supervisor CPSP in training FCPS and MS Postgraduate trainees, Member Pakistan society of Neurosurgeons. Dr. Alamgir Khan has done first brain surgery in Capital Hospital after his appointment. Dr. Izhar Sadiq Khan is working as Medical Officer in Neurosurgery department. The department consists of 12 beds and conducts all sorts of Neurosurgical procedures on regular basis. The department provides care to outpatients and inpatients with conditions requiring surgery. Neurosurgery department is in process of recognition with CPSP for training post graduate residents and almost fulfils the requirements needed as per CPSP criteria. During 1st year Neurosurgery department has provided services to more than 2,500 patients including inpatient care and surgical interventions. The department is expanding day by day and Dr Alamgir Khan and his colleagues are working hard to make it one of the state of art Neurosurgical center very soon.",
    keyPersons: [
      {
        name: "Dr. Alamgir Khan",
        designation: "Associate Neurosurgeon",
        image: "",
      },
    ],
    icon: "neuro.svg",
  },
  {
    title: "Orthopedics Department",
    description:
      "The Department of Orthopedic and Trauma Surgery is headed by Dr. Saeed Ahmed (MBBS, MS, Fellowship in ilizarov surgery) assisted by Dr. Abdul Haq. It has 21 beds including usage of the officer's ward. It looks after all the patients who need treatment for bone and joint injuries. Fractures are treated non surgically and also surgically by internal fixation and the whole range of bone and joint surgery is performed here. Knee arthroscopy and hip, knee and shoulder joint replacement are also performed. A busy OPD of around 130 ? 150 patients is run 3 days a week. House officers are trained here for their surgical and allied rotation. Post graduate training for FCPS (Orthopedic surgery) is also imparted in this department.",
    keyPersons: [
      {
        name: "Dr. Saeed Ahmed",
        designation: "Orthopaedic Surgeon",
        image: "",
      },
    ],
    icon: "ortho.svg",
  },
  {
    title: "Paediatric Surgery Department",
    description:
      "The newly established Pediatric surgery department is headed by Dr Bashir ur Rahman MBBS, MD, FCPS (Pediatric Surgery). Currently the department consists of 7 bedded ward and is entertaining all type of surgeries which includes pediatric general surgery, neonatal surgery, pediatric urology and congenital anomalies. By the establishment of pediatric surgery department not only referral of CDA patients to other hospitals has stopped, but flow of non-entitled patients has also increased.",
    keyPersons: [
      {
        name: "Dr Bashir ur Rahman",
        designation: "Peadiatric Surgeon",
        image: "",
      },
    ],
    icon: "paediatric.svg",
  },
  {
    title: "Paediatrics Department",
    description:
      "Department of Pediatrics of Capital Hospital is amongst the 04 big departments of the hospital. It is headed by Dr. Raheela Nasir (MBBS, FCPS).Dr. Muzammil Kausar (MBBS, DCH),Asscoiate Neonatologist and Dr. Attiya Naveed (MBBS, DCH),Associate Paediatrician,Dr. Nasir Rafiq is Registrar Pediatrics department. Pediatrics department is a 40 bedded unit with nursery, besides that patient are seen in pediatric preventive unit along with immunization. The immunization is carried out according to EPI schedule to cover diphtheria, pertussis, tetanus, hepatitis, H influenza, pneumonia, opv and ipv along with special administration of injections for measles and opv polio eradication programs. Patients are also seen in nutrition and lactation management clinic and main pediatric opd / pediatric emergency Procedures such as neonatal resurscitation, basic life support, incubator care, phototherapy, exchange transfusion, ventilation (invasive & non-invasiv), lumber puncture, gastric aspiration and analysis, TB investigation and DOT therapy (according to NTP). Academic activity of the department is continuous for post graduate trainees and is repeated every 03 monthly with each new group of house officers. The training is supplemented with a test / viva at the end of rotation.",
    keyPersons: [
      {
        name: "Dr. Raheela Nasir",
        designation: "Consultant Paediatrician",
        image: "",
      },
      {
        name: "Dr. Atia Naveed",
        designation: "Paediatrician",
        image: "",
      },
    ],
    icon: "paediatricdep.svg",
  },
  {
    title: "Physiotherapy Department",
    description:
      "Physiotherapy department was established in January 2000 in Capital Hospital. This department is rendering valuable services to thousands of people of Islamabad / Rawalpindi. The department is fully equipped with different type of gadgets required for therapeutic as well as rehabilitation services such as Hydrotherapy, ice therapy, electro therapy, wax therapy, manual therapy, ultrasound therapy and speech therapy.",
    keyPersons: [
      {
        name: "Mr. Zulfiqar",
        designation: "Supervisor Physiotherapy",
        image: "",
      },
    ],
    icon: "physio.svg",
  },
  {
    title: "Plastic Surgery Department",
    description:
      "Reconstructive Plastic surgery is used to repair and reconstruct missing or damaged tissue and skin. The main aim of plastic surgery is to restore the function and appearance of tissue and skin so it's as close to normal as possible. Cosmetic surgery is a branch of Plastic surgery in which surgery is carried out solely to change a person's appearance to achieve what they feel is a more desirable look. Plastic surgery can be used to repair Abnormalities that have existed from birth, such as a cleft lip and palate, webbed fingers, and birthmarks, Areas damaged by the removal of cancerous tissue, such as from the face or breast, Extensive burns or other serious injuries. Plastic surgery can often help improve a person's self-esteem, confidence and overall quality of life. Reconstructive plastic surgery is carried out free of charge for entitled patients of Capital Hospital CDA, Islamabad. Dr. Yasir Iqbal is the Consultant Plastic Surgeon heading the department under kind supervision of Dean of General Surgery, Project Director and the worthy Executive Director. He is FCPS plastic surgeon with experience of dealing both adult and pediatric plastic surgery cases in many centers of Pakistan and abroad. Some of the techniques used during plastic surgery are: Skin grafts, Skin flap surgery, Vacuum closure, Congenital defects surgery, Burn surgeries, Hand and Limb surgeries, Skin cancer removal and reconstruction. A variety of Cosmetic procedures are also done when required.",
    keyPersons: [
      {
        name: "Dr. Yasir Iqbal",
        designation: "Plastic Surgeon",
        image: "",
      },
    ],
    icon: "plastic.svg",
  },
  {
    title: "Psychiatry Department",
    description:
      "The Department of Psychiatry is headed by Ch. Muhammad Ali Randhawa. It comprises of daily OPD and 10 bedded ward, 07 beds reserved for females and 03 for males along with facility of admission in officers medical ward. Master / M,Phil level certified internships are conducted in psychiatry department under the supervision of psychiatrist. ENT department refer their cochlear implant patients for assessment of behavioral and developmental process.",
    keyPersons: [
      {
        name: "Vacant",
        designation: "Psychiatrist",
        image: "",
      },
    ],
    icon: "psychiatry.svg",
  },
  {
    title: "Radiology Department",
    description:
      "Dr. Shazia Yusuf, (Fellowship in cross sectional imaging, university of Toronto, Canada, FCPS) Head of Radiology imaging, introduced and set new trends of patient care and management with initiation of CT, MRI and CT angiography in the Capital Hospital, CDA in 2011. She has not only extensive and outstanding experience of working in Pakistan but also very well trained and qualified from University of Toronto, Canada. One of her academic achievement was starting of FCPS and MCPS training in Capital Hospital, CDA, as she was already CPSP supervisor. She's sharing burden of all patients referred to imaging department and is contributing towards patients diagnosis, leading to appropriate management. Imaging radiology team includes Medical Officers, Postgraduate trainees, Imaging technologists, Radiographers, and Medical transcriptionists. Loads of CT and MRI imaging protocols are available according to patients presentation and referral requirements. In terms of MRI, these include MRI brain with stroke, pituitary, inner ear, MRI cervical, thoracic, lumbosacral spine, knee, shoulder, elbow and ankle joints. With regards to CT, these include brain, neck, chest, abdomen, pelvis, spine, limbs, joints and angiographies. Lots of special CT protocols like triphasic, CT colonoscopy, bronchoscopy depending upon clinical scenarios are also available. Department of general radiology is look after by Dr. Birgis Fatima.",
    keyPersons: [
      {
        name: "Dr. Shazia Yousaf",
        designation: "Imaging Radiologist",
        image: "",
      },
      {
        name: "Dr. Birjis Fatima",
        designation: "Radiologist",
        image: "",
      },
    ],
    icon: "radiology.svg",
  },
  {
    title: "Spinal Surgery Department",
    description:
      "The department of Spine surgery established in March 2022 has been functional for nearly a year. The department is headed by Dr Muhammad Khalid MBBS, MS (Neurosurgery), Fellowship Spine surgery (CMH), Advance Spine fellowship (UK, USA), Pediatric Spinal fellowship (Turkey). He is a distinguished surgeon with research papers and publications in a number of recognized journals of Pakistan. Furthermore, the head of specialty is assisted by Dr Komal Rasheed under his supervision. The Spinal outpatient is serving CDA entitled as well as non entitled patients. A vast number of spinal disorders including Trauma (RTA, fall etc), TB Spine, Tumors, Degenerative spine and disc diseases are treated conservatively and surgically using latest / advance MIS (Minimal Invasive Surgical) approach. For admissions, the inpatient facility comprises of a state of the art male and female spinal ward.",
    keyPersons: [
      {
        name: "Dr. Muhammad Khalid",
        designation: "Spinal Surgeon",
        image: "",
      },
    ],
    icon: "spinal.svg",
  },
  {
    title: "Store Department",
    description:
      "This department deals with all matter of stock and also include inventory control of stock,expensing of stock to various department.",
    keyPersons: [
      {
        name: "Vacant",
        designation: "Store Officer",
        image: "",
      },
    ],
    icon: "store.svg",
  },
  {
    title: "Urology Department",
    description:
      "The Urology department of Capital Hospital had been established in mid 1992 and currently looked after by Dr. Rashid Mehmood (Registrar Urology) assisted by Dr. Muhammad Azad. The department is recognized for house job training and provides services to urology patients in casualty, outdoor and indoor. Urological Surgeries like PCNL, nephron sparing surgeries, augmentation cystoplasties, cystectomy with ileal conduit, uretroplasties, urethroplasties, endoscopic procedures like TURP,TURBT etc and laparoscopic nephrectomies are being performed with no major complications. It is also equipped with modern lithotripter for conservative treatment of urinary calculi.",
    keyPersons: [
      {
        name: "Dr. Rashid Mehmood",
        designation: "Registrar Urology",
        image: "",
      },
    ],
    icon: "urology.svg",
  },
];

export const AboutusRoutes = [
  { name: "Chairman CDA Message", link: "/chairman-cda-message" },
  { name: "All Honourable Chairman", link: "/all-honourable-chairman" },
  { name: "Board Members", link: "/board-members" },
  { name: "Board Meetings", link: "/board-meetings" },
  // { name: "CDA Training Academy", link: "/cda-training-academy" },
  { name: "CDA History", link: "/cda-history" },
];

export const AboutIslamabadRoutes = [
  { name: "About Islamabad", link: "/about-islamabad" },
  { name: "Destinations in Islamabad", link: "/destinations-in-islamabad" },
  { name: "Adventures", link: "/adventures" },
  { name: "Hospitality", link: "/hospitality" },
  { name: "Universities of Islamabad", link: "/universities-of-islamabad" },
  { name: "CDA Model School", link: "/cda-model-school" },
  // { name: "Hospitals", link: "/hospitals" },
  { name: "Hospital of Islamabad", link: "https://capitalhospital.cda.gov.pk/" },
  // { name: "CDA Hospital", link: "/cda-hospital" },
];


export const hospitalsRoutes = [
  { name: "Private Hospitals", link: "/hospitals" },
  { name: "CDA Hospitals", link: "https://capitalhospital.cda.gov.pk/" },
];

export const LegislationRoutes = [
  { name: "CDA Ordinance 1960", link: "/cda-ordinance-1960" },
  { name: "Procedures", link: "/procedures" },
  { name: "By-Laws & Regulations", link: "/by-laws-regulations" },
];

export const EServicesRoutes = [
  { name: "CDA online", link: "https://owo.cda.gov.pk/" },
  {
    name: "Online Payments",
    link: "https://owo.cda.gov.pk/BillsPayment/BillPayment.aspx",
  },
  {
    name: "Duplicate Water Bill",
    link: "https://owo.cda.gov.pk/duplicatewaterbill.aspx",
  },
  {
    name: "Duplicate Property Tax Bill",
    link: "https://owo.cda.gov.pk/duplicatepropertybill.aspx ",
  },
  { name: "Selfie with Tree", link: "https://cda.gov.pk/portals/public/login" },
  {
    name: "Register Complaint",
    link: "https://cda.gov.pk/portals/cms",
  },



  // {
  //   name: "Complaint Management System",
  //   link: "https://complaints.cda.gov.pk/eservices/registerComplaint.aspx",
  // },
];

export const mediaCenterRoutes = [
  { name: "News Releases", link: "news-releases" },
  { name: "Public Notices", link: "/public-notice" },
  { name: "Housing Societies", link: "/housing-societies" },
  { name: "Gallery", link: "/gallery" },
  { name: "Procurement", link: "/procurement" },
  { name: "FAQs", link: "/faqs" },
  { name: "Forms & Applications", link: "forms-and-applications" },
  { name: "Careers and Jobs", link: "careers-and-jobs" },
];

export const headerImages = [
  "home-page-slider-image.jpg",
  "opvs-slider-image.png",
];


export const housingSocietiesSubmenu = [
  { name: "Private/Commercial Projects", link: "/housing-societies" },
  { name: "Illegal Housing Societies", link: "/illegal-housing-schemes" },
];

export const publicNoticesRoutes = [
  { name: "Public Notice", link: "/public-notice" },
  // { name: "Illegal Housing Schemes", link: "/illegal-housing-schemes" },
  {
    name: "Balloting Announcement",
    link: "/balloting-sector-c16-i15",
  },
  { name: "Auction Notices", link: "/auction-notices" },
  // { name: "Open Auction of Plots", link: "/open-auction-of-plots" },
];

export const procurementRoutes = [
  { name: "Tenders", link: "/tenders" },
  { name: "Procurement Plans", link: "/procurement-plans" },
];

export const dataReaserchRoutes = [
  { name: "CDA Projects", link: "/cda-projects" },
  // { name: "Park Enclave", link: "/park-enclave" },
  // {
  //   name: "Commercial Building Project Status",
  //   link: "/commercial-building-project-status",
  // },
  { name: "CDA Budget", link: "/cda-budget" },
  { name: "Online Property Verification", link: OPVS_URL },
];

export const wingDirectoratesRoutes = [
  { name: "Chairman Secretariat", link: "chairman-secretariat" },
  { name: "Administration Wing", link: "administration-wing" },
  { name: "Estate Wing", link: "estate-wing" },
  { name: "Engineering Wing", link: "engineering-wing" },
  { name: "Office of Deputy Financial Advisor I & II", link: "office-of-deputy-financial-advisor" },
  { name: "Planning & Design Wing", link: "planning-and-design-wing" },
  { name: "Environmental Protection Cell", link: "environmental-protection-cell" },
  { name: "Technology Wing", link: "tech-wing" },
];

export const CdaOrdinanceRelatedLinks = [
  { name: "Government of Pakistan", link: "https://pakistan.gov.pk/" },
  { name: "Cabinet Division", link: "https://www.cabinet.gov.pk/" },
  { name: "Ministry of Law", link: "https://molaw.gov.pk/" },
  // { name: "CDA Law Directorate", link: "#" },
];

export const CdaOrdinanceRelatedInfo = [
  { name: "CDA By-Laws", link: "/by-laws-regulations" },
  {
    name: "CDA Contractors List",
    link: "/pdf/cdacontractors112012.pdf",
  },
];

export const auctionNoticesRelatedLinks = [
  { name: "PPRA Pakistan", link: "https://www.ppra.org.pk/" },
  {
    name: "Security Exchange Commission of Pakistan",
    link: "https://www.secp.gov.pk/",
  },
  { name: "Pakistan Engg Council", link: "https://www.pec.org.pk/" },
];

export const relatedInfo = [
  { fileName: "Budget 2021-22", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2022-23", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2020-24", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2021-22", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2021-22", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2022-23", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2020-24", fileSize: "78 MB", link: "#" },
  { fileName: "Budget 2021-22", fileSize: "78 MB", link: "#" },
];

export const relatedLinks = [
  { name: "Government of Pakistan", link: "https://pakistan.gov.pk/" },
  { name: "Cabinet Division", link: "https://www.cabinet.gov.pk/" },
  { name: "CDA Model School", link: "cda-model-school" },
  { name: "CDA Hospital", link: "/cda-hospital" },
];

export const sortBy = [
  'Most Recent',
  'Oldest First',
  'Expiring Soon'
]

export const years = [
  "1965",
  "1966",
  "1967",
  "1968",
  "1969",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1977",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

//Hospitals of Islamabad
// export const hospitals = [
//   {
//     name: "Pakistan Institute of Medical Sciences (PIMS)",
//     tel: "+92-51-9260500",
//     address: "G-8/3, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "Federal Government Services Hospital",
//     tel: "+92-51-9214965",
//     address: "Polyclinic, Service Road, G-6/1, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "Capital Hospital (CDA Hospital)",
//     tel: "+92-51-9253003/220",
//     address: "Near Melody G-6/3, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "National Institute of Health",
//     tel: "+92-51-9253003/220",
//     address: "Chak Shahzad, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "Nuclear Oncology & Radiotherapy Institute (NORI)",
//     address: "G-8/3, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "Nescom Hospital",
//     address: "Sector H-11, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "KRL Hospital",
//     address: "Sector G-9/1, Islamabad",
//     type: "gov",
//   },
//   {
//     name: "Shifa International Hospital",
//     type: "private",
//   },
//   {
//     name: "Ali Medical Hspital",
//     type: "private",
//   },
//   {
//     name: "Maroof International Hospital",
//     type: "private",
//   },
//   {
//     name: "Kulsum International Hospital",
//     type: "private",
//   },
//   {
//     name: "Integrated Health Services",
//     type: "private",
//   },
//   {
//     name: "HS Children's Medical Center",
//     type: "private",
//   },
//   {
//     name: "Islamic International Medical Complex",
//     type: "private",
//   },
//   {
//     name: "Dar-ul-Shifa Hospital",
//     type: "private",
//   },
//   {
//     name: "Islamabad Specialist Clinic",
//     type: "private",
//   },
//   {
//     name: "Aslam Memorial Medicare Hospital",
//     type: "private",
//   },
//   {
//     name: "Islamabad Private Hospital",
//     type: "private",
//   },
//   {
//     name: "Elahi Medical Center",
//     type: "private",
//   },
//   {
//     name: "Fauji Foundation Hospital",
//     type: "private",
//   },
//   {
//     name: "Hope Medical Dental & Diagnostics",
//     type: "private",
//   },
// ];

//Hospitality of Islamabad
export const hospitality = [
  {
    name: "Ramada Hotel",
    tel: "",
    address: "1, Club Road, Murree Road, Islamabad",
    image: "ramada.jpg",
  },
  {
    name: "Hill View Hotel",
    tel: "",
    address: "Main Jinnah Super Market, Islamabad",
    image: "hill-view.jpg",
  },
  {
    name: "Roomy Hotel Hotel",
    tel: "",
    address: "KFC back, Agha Khan Rd, next to total petrol pump, F-6 Markaz, Islamabad",
    image: "roomy-signature.jpg",
  },
  {
    name: "Marriott Hotel",
    tel: "+92-51-111223344",
    address: "Agha Khan Road, Shalimar 5, Islamabad",
    image: "Marriott-Hotel.png",
  },
  {
    name: "Serena",
    tel: "+92-51-111133133",
    address: "Opposite convention centre, Khayaban-e-Suhrawardy, Islamabad",
    image: "Serena.png",
  },
  {
    name: "Islamabad Hotel",
    tel: "+92-51-2827311-20",
    address:
      "Agha Khan Road, Shalimar 5, Islamabad.G-6/1, Civic Center, Islamabad",
    image: "Islamabad-hotel.png",
  },
  {
    name: "Best Western Hotel",
    tel: "+92-51-2277460",
    address: "6, Islamabad Club Road, Islamabad",
    image: "best-western.jpg",
  },
  {
    name: "Envoy Continental Hotel",
    tel: "+92-51-2273971-7",
    address: "111-East, Fazal-e-Haq Road, Blue Area, Islamabad",
    image: "envoy-continental.png",
  },
  {
    name: "Islamabad Regency Hotel",
    tel: "+92-51-2276721-2/2874056",
    address: "1, Club Road, Islamabad",
    image: "Islamabad-Regency-hotel.png",
  },
  {
    name: "Crown Plaza Hotel",
    tel: "+92-51-2277890",
    address: "99-E, Jinnah Avenue, Blue Area, Islamabad",
    image: "crown-plaza.png",
  },
  {
    name: "Margalla Hotel",
    tel: "+92-51-2276500",
    address: "M2, Near Convention Centre, Kashmir Road, Islamabad",
    image: "margalla-hotel.png",
  },
  // {
  //   name: "Pak Palace Hotel",
  //   tel: "+92-51-2877680-81",
  //   address: "Club Road, Near Rawal Dam Chowk, Islamabad",
  //   image: "Pak-Palace-Hotel.png",
  // },
];

//Universities of Islamabad
export const universities = [
  {
    name: "Quaid-i-Azam University",
    address: "Quaid-i-Azam Univeristy Islamabad, 45320, Pakistan",
    website: "www.qau.edu.pk",
    type: "gov",
    image: "cleanArtboard 1-100-QUA.jpg",
    link: "https://qau.edu.pk/",
  },
  {
    name: "Allama Iqbal Open University",
    website: "www.aiou.edu.pk",
    address: "Sector H-8, Islamabad",
    type: "gov",
    image: "cleanArtboard 7-100-AllamaIqbal.jpg",
    link: "https://www.aiou.edu.pk/",
  },
  {
    name: "International Islamic University",
    website: "www.iiu.edu.pk",
    address: "Shah Faisal Masjid Campus, Islamabad",
    type: "gov",
    image: "iiui-logo.jpg",
    link: "https://www.iiu.edu.pk/",
  },
  {
    name: "National Defence University Islamabad",
    website: "www.ndu.edu.pk",
    address: "National Defence University, Islamabad",
    type: "gov",
    image: "cleanArtboard 5-100-NDU.jpg",
    link: "https://www.ndu.edu.pk/",
  },
  {
    name: "Pakistan Institute of Engineering & Applied Sciences",
    website: "www.pieas.edu.pk",
    address: "Pakistan Institute of Engineering & Applied Sciences",
    type: "gov",
    image: "cleanArtboard 4-100-pieas.jpg",
    link: "http://www.pieas.edu.pk/",
  },
  {
    name: "COMSATS Institute of Information Technology",
    website: "www.ciit-isb.edu.pk/",
    address: "Plot # 30, Sector H-8, Islamabad",
    type: "gov",
    image: "cleanArtboard 3-100-comsats.jpg",
    link: "https://talloiresnetwork.tufts.edu/comsats-institute-of-information-technology-pakistan/",
  },
  {
    name: "Institute of Space Technology",
    address:
      "Institute of Space Technology, P.O. Box 2750, Islamabad 44000, Pakistan",
    website: "www.ist.edu.pk",
    type: "gov",
    image: "cleanArtboard 9-100-ist.jpg",
    link: "http://www.ist.edu.pk/",
  },
  {
    name: "Air University",
    website: "www.au.edu.pk",
    address: "Air University Sector E-9 Islamabad",
    type: "gov",
    image: "air-logo.jpg",
    link: "https://www.au.edu.pk/",
  },
  {
    name: "Federal Urdu University of Arts, Science and Technology Islamabad",
    website: "www.fuuastisb.edu.pk",
    address: "Sector G-7/1, Islamabad - Pakistan",
    type: "gov",
    image: "cleanArtboard 2-100-Urdu.jpg",
    link: "https://www.fuuastisb.edu.pk/",
  },
  {
    name: "National University of Modern Languages",
    website: "www.numl.edu.pk",
    address: "National University of Modern Languages",
    type: "private",
    image: "cleanNUML-100.jpg",
    link: "https://www.numl.edu.pk/",
  },
  {
    name: "Bahria University",
    website: "www.bahria.edu.pk",
    address: "Shangrilla Road, Sector E-8, Islamabad - Pakistan",
    type: "private",
    image: "cleanbahria-100.jpg",
    link: "https://www.bahria.edu.pk/",
  },
  {
    name: "Center for Advanced Studies in Engineering",
    website: "www.case.edu.pk",
    address:
      "Sir Syed Memorial Building, 19-Attaturk Avenue, Sector # G-5/1, Islamabad, Pakistan.",
    type: "private",
    image: "cleanCase-100.jpg",
    link: "https://case.edu.pk/",
  },
  {
    name: "Foundation University",
    website: "www.fui.edu.pk",
    address: "Defence Mall, Jinnah Avenue,DHA Phase - I. Islamabad, Pakistan",
    type: "private",
    image: "cleanfoundation-100.jpg",
    link: "https://www.fui.edu.pk/",
  },
  {
    name: "Hamdard University Karachi, Islamabad Campus",
    website: "www.fuuastisb.edu.pk",
    address: "Sector G-7/1, Islamabad - Pakistan",
    type: "private",
    image: "hamdard.png",
    link: "https://www.fuuastisb.edu.pk/",
  },
  {
    name: "Iqra University",
    website: "www.iqraisb.edu.pk",
    address: "Plot # 5, Sector H-9, Islamabad",
    type: "private",
    image: "cleaniqra-100.jpg",
    link: "http://www.iqraisb.edu.pk/",
  },
  {
    name: "Mohammad Ali Jinnah University",
    website: "www.jinnah.edu.pk",
    address: "Islamabad Expressway Kahuta Road, Zone-V, Islamabad.",
    type: "private",
    image: "maju-logo.jpg",
    link: "http://www.jinnah.edu",
  },
  {
    name: "National Defence University, Islamabad",
    website: "www.ndu.edu.pk",
    address: "National Defence University, Islamabad",
    type: "private",
    image: "cleanArtboard 5-100-NDU.jpg",
    link: "https://www.ndu.edu.pk/",
  },
  {
    name: "National University of Sciences and Technology",
    website: "www.niit.edu.pk",
    address:
      "NUST School of Electrical Engineering and Computer Science, Sector H-12, Islamabad, Pakistan.",
    type: "private",
    image: "cleannust-100.jpg",
    link: "http://niit.edu.pk/",
  },
  {
    name: "National University of Computer & Emerging Sciences Islamabad",
    website: "www.nu.edu.pk",
    address: "FAST House, Rohtas, Islamabad",
    type: "private",
    image: "cleannu-100.jpg",
    link: "https://www.nu.edu.pk/",
  },
  {
    name: "Shaheed Zulfiqar Ali Bhutto Institute of Science and Technology (SZABIST)",
    website: "www.szabist-isb.edu.pk",
    address: "Street No# 09 Plot No# 67 Sector# H - 8/4 Islamabad Pakistan",
    type: "private",
    image: "szabbist-logo.jpg",
    link: "https://szabist-isb.edu.pk/",
  },
  {
    name: "The Institute of Certified Public Accountants of Pakistan",
    type: "private",
    image: "cpa-logo.jpg",
  },
  {
    name: "Riphah International University",
    type: "private",
    image: "rippha-logo.jpg",
  },
];

// Park Enclave File pdf
export const ParkEnclaveFileData = [
  {
    fileName: "MCB Branches for brochure",
    fileLink: "https://example.com/file1.pdf",
    fileSize: "2.5 MB",
  },
  {
    fileName: "MCB Branches for brochure",
    fileLink: "https://example.com/file1.pdf",
    fileSize: "2.5 MB",
  },
  {
    fileName: "Askari Bank Branches for brochure",
    fileLink: "https://example.com/file1.pdf",
    fileSize: "2.5 MB",
  },
  {
    fileName: "Brochure Park Enclave Phase-II",
    fileLink: "https://example.com/file1.pdf",
    fileSize: "2.5 MB",
  },
];

// Home Page Quick links
export const quickLinks = [
  {
    icon: "ql1.svg",
    Hoveredicon: "ql1Hover.svg",
    title: "Forms & Applications",
    link: "/forms-and-applications",
  },
  {
    icon: "ql2.svg",
    Hoveredicon: "ql2Hover.svg",
    title: "Procedures",
    link: "/procedures",
  },
  {
    icon: "ql3.svg",
    Hoveredicon: "ql3Hover.svg",
    title: "Online Property Verification",
    link: OPVS_URL,
  },
  {
    icon: "ql4.svg",
    Hoveredicon: "ql4Hover.svg",
    title: "Public Notices",
    link: "/public-notice",
  },
  {
    icon: "ql5.svg",
    Hoveredicon: "ql5Hover.svg",
    title: "Illegal Housing Schemes",
    link: "/illegal-housing-schemes",
  },

  {
    icon: "ql6.svg",
    Hoveredicon: "ql6Hover.svg",
    title: "Housing Societies",
    link: "/housing-societies",
  },
  {
    icon: "ql7.svg",
    Hoveredicon: "ql7Hover.svg",
    title: "Auction Notices",
    link: "auction-notices",
  },
  {
    icon: "ql8.svg",
    Hoveredicon: "ql8Hover.svg",
    title: "Online Payments",
    link: "https://owo.cda.gov.pk/BillsPayment/BillPayment.aspx",
  },
];

// Home Page News & Updates
export const newsAndUpdates = [
  {
    date: "Oct 20, 2023 ",
    badge: "News Release",
    description:
      "CDA takes action on illegal constructions and violation of building by-laws in Islamabad",
  },
  {
    date: "Oct 20, 2023 ",
    badge: "News Release",
    description:
      "Chairman CDA Captain (r) Anwar ul Haq visited Children Hospital of the Pakistan Institute of Medical Sciences (PIMS)",
  },
  {
    date: "Oct 20, 2023 ",
    badge: "Tender",
    description: "Prime Minister Shehbaz Sharif inaugurates Bhara Kahu Bypass",
  },
  {
    date: "Oct 20, 2023 ",
    badge: "News Release",
    description:
      "CDA, Mari Petroleum join forces for 2,000 sapling plantation in D-12 Green Belt",
  },
  {
    date: "Oct 20, 2023 ",
    badge: "News Release",
    description: "CDA seals car showroom",
  },
];
// Home Page Featured

export const featuredData = [
  {
    name: "Pay your bills Online",
    link: "https://owo.cda.gov.pk/BillsPayment/BillPayment.aspx",
  },
  { name: "Illegal Housing society", link: "/illegal-housing-schemes" },
  { name: "Tenders", link: "/tenders" },
  { name: "Selfie with Tree", link: "https://cda.gov.pk/portals/public/login" },
  { name: "Register Complaint", link: "https://cda.gov.pk/portals/cms" },

];

export const announcements = [
  "The Margalla Hills Tree Plantation Drive is set to begin on July 22, 2024.  Join us for a greener Islamabad!.",
  "The Margalla Hills Tree Plantation Drive is set to begin on July 22, 2024.  Join us for a greener Islamabad!.",
  "The Margalla Hills Tree Plantation Drive is set to begin on July 22, 2024.  Join us for a greener Islamabad!.",
];

// CDA Hospital Public Notices
export const publicNotices = [
  {
    date: "Oct 20, 2023 ",
    title: "Two Departments of CDA Hospital Approved for Specilization",
    badge: "News Release",
    description:
      "Department of Endo-Chronology of CDA Hospital has been duly approved by Physicians and Surgeons Pakistan for specialization after which CDA Hospital has got the honor of being a recognized Super Institute for Endo-Chronology. Earlier, specialization in various fields was being done in CDA Hospital. Specializations in two more areas have been added under the direction of the director, including super-speciality in gynecology and endocrinology",
  },
  {
    date: "Oct 20, 2023 ",
    title: "CDA Hospital Gets New Facilitation Centre",
    badge: "News Release",
    description:
      "A new patient facilitation and registration centre was inaugurated at the Capital Development Authority (CDA) Hospital on Saturday. The facilitation centre is not only equipped with modern technology, but also offers a comfortable sitting area for the patients and their family.",
  },
  {
    date: "Oct 20, 2023 ",
    title: "Additional block inaugurated in Capital Hospital",
    badge: "News Release",
    description:
      "Capital Development Authority (CDA)Chairman Amer Ali Ahmed inaugurated the new building as well as other health facilities. The additional block’s construction had begun in 2020.",
  },
];

///search page content
export const searchContent = [
  {
    title: "Lorem Ipsum",
    description:
      "Lorem ipsum dolor sit amet consectetur. Massa bibendum pellentesque velit orci sed. Tempus tellus felis aliquam sit est sed venenatis. Habitant integer risus in netus sit. Non tortor vitae elementum vitae diam. Pulvinar quam imperdiet tortor lectus gravida tristique morbi porttitor. Lorem orci vel tincidunt integer nibh venenatis suspendisse convallis ornare.",
  },
  {
    title: "Lorem Ipsum",
    description:
      "Lorem ipsum dolor sit amet consectetur. Massa bibendum pellentesque velit orci sed. Tempus tellus felis aliquam sit est sed venenatis. Habitant integer risus in netus sit. Non tortor vitae elementum vitae diam. Pulvinar quam imperdiet tortor lectus gravida tristique morbi porttitor. Lorem orci vel tincidunt integer nibh venenatis suspendisse convallis ornare.",
  },
  {
    title: "Lorem Ipsum",
    description:
      "Lorem ipsum dolor sit amet consectetur. Massa bibendum pellentesque velit orci sed. Tempus tellus felis aliquam sit est sed venenatis. Habitant integer risus in netus sit. Non tortor vitae elementum vitae diam. Pulvinar quam imperdiet tortor lectus gravida tristique morbi porttitor. Lorem orci vel tincidunt integer nibh venenatis suspendisse convallis ornare.",
  },
];

export const trialData = [
  {
    title: "Trail 1",
    description:
      "It will lead you to the top terminal of the Pir Sohawa road, in more or less two hours. Extending the walkway, you will reach Monal Restaurant in twenty minutes",
    mapLink:
      "https://www.google.com/maps/place/Trail+1/@33.7314627,73.0137082,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbfd7c643172f:0x814d2f19a571bc63!8m2!3d33.7314583!4d73.0162831!16s%2Fg%2F11j7l9b_d7?entry=ttu",
    img: "trail1.jpg",
  },
  {
    title: "Trail 2",
    description:
      "Starts out just above Pir Sohawa, the point located near Islamabad Zoo. It is a 1 to 1.5 hours walk and leads you to the Daman-e-Koh viewpoint. From there you can continue upward, to the cactus ridge.",
    mapLink:
      "https://www.google.com/maps/place/Trail+2+Alternate+Start+Point/@33.7346115,73.0520801,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbf1037e97f57:0xcd2d9a1c488a7e32!8m2!3d33.7346071!4d73.054655!16s%2Fg%2F11c706r1jy?entry=ttu",
    img: "trail2.jpg",
  },
  {
    title: "Trail 3",
    description:
      "The most famous and oldest hiking track in Islamabad is trail 3. It starts from Margalla road sector F-6. The trail can be challenging, due to steep hills. The course will lead you to the which goes up to a view point, which takes about a 30 to 50 minutes to reach. After reaching the viewpoint, you can continue on for an additional easy-going 45 to 60 minutes and reach Pir Sohawa. There you can choose from two restaurants for food. The Monal and La Montana. In total, it is approximately a one hour and thirty-minute walk.",
    mapLink:
      "https://www.google.com/maps/d/viewer?mid=17uElNWu1VqqvVuyn2d4Wka5Upeg&femb=1&ll=33.75183553909915%2C73.07570199999996&z=13",
    img: "trail3.jpg",
  },
  {
    title: "Trail 4",
    description: "It is a link between Trails 3 and 5.",
    mapLink:
      "https://www.google.com/maps/d/viewer?mid=1JpUDuVX8bW0H6k_jcs2sqF3oZC0&femb=1&ll=33.75868470875725%2C73.07668890000001&z=17",
    img: "trail4.jpg",
  },
  {
    title: "Trail 5",
    description:
      "It begins from Margalla road in sector F-5, and people find it easier to climb. Trails 3 and 5 run parallel for some distance, and are connected via trail 4 at certain points. Trail 5 will lead you to the top of Pir Sohawa road. You can switch from trail 3 to 5 by walking an extra distance of 1.5 km. The estimated time to cover the distance on the trail is about 2.5 to 3.5 hours.",
    mapLink:
      "https://www.google.com/maps/d/viewer?mid=1oHEZqyx6pXSfl8ECGy_vNdEQjSU&femb=1&ll=33.756099988967684%2C73.08246000000001&z=14",
    img: "trail5.jpg",
  },
  {
    title: "Trail 6",
    description:
      "This trail is sparingly used as the entrance is not known to many people. It starts from the back of the Faisal Mosque in Sector E7, near the tree planting Project sign. Trails will lead you through a valley along a well defined route that guides you to the top terminal of Pir Sohawa road. The trail has a track for mountain bikes, and a bird watching point.",
    mapLink:
      "https://www.google.com/maps/d/viewer?mid=1U8b8l8s2xn47kNruOILvIVwK760&femb=1&ll=33.743259070762456%2C73.03085500000002&z=14",
    img: "trail6.jpg",
  },
];

export const climbingData = [
  {
    title: "1. Jasmine Corner & Music Lounge:",
    description:
      "Two interesting sites, approachable using same parking (3 kms above Daman-e-Koh), Jasmine Corner site maybe developed as family picnic spot with arrangement of umbrellas, benches and BBQ place. Climbing routes are good for beginners and novice climbers (4 to 5 French grade)",
    img: "1.jpg",
  },
  {
    title: "2. Saidpur Village:",
    description:
      "Outside the village, there are good sites for various grade climbing which include:<br/>a. Beetles Nest / Hang Loose 5a+ with good space for large teams and spectators at belay area <br/>b. Saxony Ramp-easy grade climbing for beginners (route bolts are missing)<br/>c. Teamwork-Difficult approach, with space for large teams-grade 5 to 6+<br/>d. Belvedere-Difficult approach, no space for large groups-grade 6+<br/>e. Monkey Tower-easy route-Grade 4+ to 5< br /> f.God’s Rock-No space at base-Grade 5 to 6",
    img: "2.jpg",
  },
  {
    title: "3. Trail 3",
    description:
      "Using the parking of trail 3, its 15 to 20 minutes uphill walk to Legacy rock with multiple routes of advanced level starting from 6a. No vast space available at belay area",
    img: "2.jpg",
  },

  {
    title: "4. Daman-e-Koh / Jungle Rock:",
    description:
      "Using the parking of Daman-e-Koh, one has to follow a tricky uphill trail. The place is ok for small parties with advance level climbing routes of 6a+",
    img: "1.jpg",
  },
  {
    title: "5. Shahdara Picnic Spot:",
    description:
      "An interesting place but right on the road side. Routes available from 5a onwards. Easy approach, parking available with good space for teams and spectators",
    img: "2.jpg",
  },
];

export const parksData = [
  {
    title: "Pakistan Monument",
    description:
      "The Pakistan Monument in Islamabad is a national symbol representing the unity of the nation's provinces and territories. It's an architectural marvel depicting the Pakistan movement, and the country's culture. From an aerial view, the monument resembles the star and crescent from Pakistan's flag, honouring those who sacrificed for the country's future",
    mapLink:
      "https://www.google.com/maps/place/Pakistan+Monument/@33.693339,73.0656535,17z/data=!4m6!3m5!1s0x38dfbfe8eb6fcb0d:0x8b69f5187f009ac1!8m2!3d33.6933346!4d73.0682284!16s%2Fm%2F02rb63p?entry=ttu",
    img: "Pakistan-Monument.png",
  },

  {
    title: "Shakarparian Hills",
    description:
      "Shakarparian Hills, rich in history, is known for trees planted by various heads of state. This beautiful park offers a panoramic view of the city from its vantage point at an elevation of 609 metres. It's one of the favourite recreational spots in Islamabad.",
    mapLink:
      "https://www.google.com/maps/place/Shakarparian+National+Park/@33.6887061,73.0861193,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfc00721e44139:0xc76180e59480ec37!8m2!3d33.6887017!4d73.0886942!16s%2Fm%2F0rfh0yq?entry=ttu",
    img: "Shakarparian.png",
  },
  {
    title: "Daman-e-Koh",
    description:
      "Daman-e-Koh, now a popular recreational and picnic spot, offers breathtaking views of the city, especially at night. To enhance visitor the experience, golf carts have been introduced to transport visitors from the parking area to the viewpoint. Quality restaurants provide delightful cuisine for weary travelers.",
    mapLink:
      "https://www.google.com/maps/place/Daman-e-Koh/@33.7382944,73.0539481,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbf16d96fc917:0xf73e570c5d7bf4ae!8m2!3d33.73829!4d73.056523!16zL20vMGZjbTNy?entry=ttu",
    img: "Daman-e-Koh.png",
  },
  {
    title: "Buddhist Caves in Shah Allah Ditta",
    description:
      "Shah Allah Ditta, a village dating back over seven centuries, holds relics from the Buddhist era and ancient artefacts like burnt diyas and amulet-adorned trees. The caves here, including Sadhu ka Bagh, exhibit remnants of the Buddhist period, featuring 2,400-year-old murals. The caves and surrounding formations were once used by Buddhist monks, Hindu sadhus, and later, by Muslim ascetics during the Mughal era.",
    mapLink:
      "https://www.google.com/maps/place/Shah+Allah+Ditta+Caves/@33.7219775,72.9125416,17z/data=!4m10!1m2!2m1!1sBuddhist+Caves+in+Shah+Allah+Ditta!3m6!1s0x38dfbcc721ed667f:0xa3dc9afd1a8a19a5!8m2!3d33.7220572!4d72.9151352!15sCiJCdWRkaGlzdCBDYXZlcyBpbiBTaGFoIEFsbGFoIERpdHRhWiQiImJ1ZGRoaXN0IGNhdmVzIGluIHNoYWggYWxsYWggZGl0dGGSARJ0b3VyaXN0X2F0dHJhY3Rpb26aASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVUTFaMk5RVVdaQkVBReABAA!16s%2Fg%2F11f13qhvnk?entry=ttu",
    img: "shah-allahditta.svg",
  },
  {
    title: "Saidpur Village",
    description:
      "Saidpur village, nestled in the Margalla Hills on the outskirts of Islamabad, Pakistan, is a historical and cultural treasure. This rustic village preserves its traditional roots while offering a charming mix of art galleries, handicraft shops, and quaint eateries. The cobblestone streets, ancient temples, and a tranquil ambiance transport visitors to a bygone era, making it a popular spot for both locals and tourists seeking a taste of history and culture amidst the picturesque landscape.",
    mapLink:
      "https://www.google.com/maps/place/Saidpur+Village+Islamabad/@33.7436937,73.0639738,16z/data=!4m15!1m8!3m7!1s0x38dfbf6a531d028f:0xc342f64029165206!2sSaidpur,+Islamabad,+Islamabad+Capital+Territory,+Pakistan!3b1!8m2!3d33.7426552!4d73.0678287!16s%2Fm%2F07k4gr4!3m5!1s0x38dfbf9befb2fe81:0x27553d2e6af32a6a!8m2!3d33.7457425!4d73.0679565!16s%2Fg%2F11k4qsgb6r?entry=ttu",
    img: "saidpurvillage-1024x768.png",
  },
  {
    title: "Lok Virsa Cultural Center",
    description:
      "The Lok Virsa, dedicated to preserving Pakistan's folk and traditional culture, hosts artists, craftsmen, and musicians from across the country. This folk heritage museum, near Shakarparian Hills, showcases various historical stages, including embroidered costumes, jewellery, woodwork, and more.",
    mapLink:
      "https://www.google.com/maps/place/Lok+Virsa+Heritage+Museum/@33.6887242,73.0696108,17z/data=!4m10!1m2!2m1!1sLok+Virsa+Cultural+Center!3m6!1s0x38dfbfe4f6af321b:0xc48c4b4b2b94f121!8m2!3d33.6884661!4d73.0726757!15sChlMb2sgVmlyc2EgQ3VsdHVyYWwgQ2VudGVyWhsiGWxvayB2aXJzYSBjdWx0dXJhbCBjZW50ZXKSAQ9oZXJpdGFnZV9tdXNldW2aASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUnBORnB5YVhWUlJSQULgAQA!16s%2Fm%2F0283dm6?entry=ttu",
    img: "lokvirsa-1024x768.png",
  },
  {
    title: "Pothohari Arts and Crafts Village",
    description:
      "Spread across 26 acres, this village promotes indigenous arts and crafts, aiming to foster inter-provincial harmony. It includes exhibition halls, artist studios, folk theatre, and showrooms, serving as a hub for cultural activities.",
    mapLink:
      "https://www.google.com/maps/place/Craft+Village/@33.6993639,73.0818087,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbfbc00b85b57:0xc2b4fedd64465a83!8m2!3d33.6993595!4d73.0843836!16s%2Fg%2F11j0tll253?entry=ttu",
    img: "art_craft_village.jpg",
  },
  {
    title: "Rose and Jasmine Garden",
    description:
      "The Rose and Jasmine garden, located in Islamabad, Pakistan, is a botanical paradise featuring a stunning variety of roses and jasmine flowers. This lush green garden offers a mesmerising display of colourful blooms, making it a favoured destination for nature enthusiasts and those seeking a tranquil retreat. Visitors can stroll through the fragrant pathways, admire the vibrant floral arrangements, and revel in the natural beauty of these aromatic gardens, providing a serene escape within the bustling city.",
    mapLink:
      "https://www.google.com/maps/place/Rose+And+Jasmine+Garden/@33.7015198,73.0858522,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfc00aaa6f5339:0x61b64b9e90bc03f1!8m2!3d33.7015154!4d73.0884271!16s%2Fg%2F11ggs2191x?entry=ttu",
    img: "1614251697_rose-and-jasmine1.jpg",
  },
  {
    title: "Margalla Hills National Park",
    description:
      "Nestled in the foothills of the Himalayas, the Margalla Hills national park is an easily accessible destination, ideal for hiking enthusiasts. Home to diverse wildlife including leopards, deer, foxes, and more, it offers an excellent hiking experience, especially in the mild winter months.",
    mapLink:
      "https://www.google.com/maps/place/Margalla+Hills+National+Park/@33.7480632,73.0025306,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbeb60e638689:0x856d3159d217b9c6!8m2!3d33.7480588!4d73.0051055!16zL20vMDlocDN5?entry=ttu",
    img: "margallahills-2-1024x768.png",
  },
  {
    title: "Fatima Jinnah Park",
    description:
      "Fatima Jinnah Park, situated in Islamabad, Pakistan, is a serene urban retreat renowned for its vast green expanses, jogging tracks, gardens, and recreational facilities. Named after Fatima Jinnah, it provides a peaceful environment for outdoor activities and relaxation, making it a popular spot for both locals and tourists alike in the city.",
    mapLink:
      "https://www.google.com/maps/place/Fatima+Jinnah+Park+(F-9+Park)/@33.7023869,73.016843,15.95z/data=!4m6!3m5!1s0x38dfbe414e0b240b:0xa7af0147d7b95ce6!8m2!3d33.7017235!4d73.0228173!16s%2Fm%2F02z0yv4?entry=ttu",
    img: "Fatima-jinnah-Park.png",
  },
  {
    title: "Lake View Park",
    description:
      "Lake View Park in Islamabad, stands as a tranquil haven offering stunning natural beauty around Rawal Lake. Renowned for its picturesque landscape, the park provides various recreational activities such as boating, fishing, and paddle boating on the serene lake. It also hosts amenities like walking trails, play areas, and local food stalls. A favourite spot for families and tourists alike, this park serves as an ideal escape to unwind, relish nature, and partake in diverse outdoor experiences amidst the lush greenery and peaceful ambiance of Islamabad.",
    mapLink:
      "https://www.google.com/maps/search/Lake+View+Park/@33.7163942,73.1204161,15z/data=!3m1!4b1?entry=ttu",
    img: "lakeviewpark-1024x768.png",
  },
  {
    title: "Japanese Children Park",
    description:
      'At the base of Daman-e-Koh is located the Marghazar Mini Zoo and the "Japanese Children Park". The zoo itself is small and has undergone rehabilitation, yet its fascinating array of animals never fails to entertain. The Japanese Children Park, a gift from the Japanese government, is also interesting with minimalist swings made of bamboo wood.',
    mapLink:
      "https://www.google.com/maps/place/Japanese+Children+Park/@33.7345199,73.0598578,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbf0d5f7f44f1:0x51e1c85952ffd8d7!8m2!3d33.7345155!4d73.0624327!16s%2Fg%2F11xfb7tny?entry=ttu",
    img: "MarghazarZoo.png",
  },
];

// cda modal school faculty Data
export const facultyData = [
  {
    name: "Mrs. Zahida Tariq",
    bps: "17",
    subjectTeaching: "Urdu",
    lastDegree: "B.A (Urdu Lit. )",
    institutionAttended: "Punjab University",
    domicile: "Punjab",
  },
  {
    name: "Miss Tehseen Farah",
    bps: "17",
    subjectTeaching: "Art / Home Economics",
    lastDegree: "B.A (Eng. Pak. Std.)",
    institutionAttended: "Punjab University",
    domicile: "Federal",
  },
  {
    name: "Mrs. Samina Iqbal",
    bps: "17",
    subjectTeaching: "Pakistan Studies",
    lastDegree: "M.Sc (Pakistan Studies)",
    institutionAttended: "Quaid-e-Azam University",
    domicile: "AJK",
  },
  {
    name: "Mrs. Parveen Altaf",
    bps: "17",
    subjectTeaching: "Mathematics",
    lastDegree: "M.A (Economics)",
    institutionAttended: "Punjab University",
    domicile: "Punjab",
  },
  {
    name: "Miss Bushra Qazi",
    bps: "17",
    subjectTeaching: "Islamic Studies",
    lastDegree: "M.A (Islamic Studies)",
    institutionAttended: "Punjab University",
    domicile: "Punjab",
  },
  {
    name: "Mrs. Rukhsana Gulnar",
    bps: "16",
    subjectTeaching: "G. Science",
    lastDegree: "B.Sc",
    institutionAttended: "Peshawar University",
    domicile: "N.W.F.P",
  },
  {
    name: "Mrs. Fadia Gul",
    bps: "16",
    subjectTeaching: "Bio/ Chemistry",
    lastDegree: "M.Sc (Biology)",
    institutionAttended: "Quaid-e-Azam University",
    domicile: "Federal",
  },
  {
    name: "Mrs. Saeeda Yasmeen",
    bps: "16",
    subjectTeaching: "Physics/ Bio",
    lastDegree: "M.Sc(Botony)",
    institutionAttended: "Punjab University",
    domicile: "Federal",
  },
  {
    name: "Mrs. Itrat Mehdi",
    bps: "16",
    subjectTeaching: "MATHS",
    lastDegree: "M.A (Economics)",
    institutionAttended: "Peshawar University",
    domicile: "N.W.F.P",
  },
  {
    name: "Mrs. Azra Naqvi",
    bps: "16",
    subjectTeaching: "URDU",
    lastDegree: "B. A",
    institutionAttended: "Sindh, Karachi",
    domicile: "Karachi",
  },
  {
    name: "Miss Shabana Kausar",
    bps: "16",
    subjectTeaching: "Islamiat, Arabic",
    lastDegree: "M. A (Arabic)",
    institutionAttended: "Punjab University",
    domicile: "FEDERAL",
  },
  {
    name: "Mrs. Fouzia Batool",
    bps: "16",
    subjectTeaching: "ENGLISH",
    lastDegree: "M. A (English Lit.)",
    institutionAttended: "Karachi University",
    domicile: "KARACHI",
  },
  {
    name: "Miss Rukhsana Hayat",
    bps: "16",
    subjectTeaching: "ENGLISH",
    lastDegree: "M. A(English Lit.)",
    institutionAttended: "Punjab University",
    domicile: "FEDERAL",
  },
  {
    name: "Miss Sumaira Rafique",
    bps: "16",
    subjectTeaching: "S.Std, Maths",
    lastDegree: "M. A (Political Science)",
    institutionAttended: "Punjab University",
    domicile: "FEDERAL",
  },
  {
    name: "Mrs. Shagufta Shahid",
    bps: "16",
    subjectTeaching: "URDU",
    lastDegree: "M. A (Urdu)",
    institutionAttended: "Punjab University",
    domicile: "PUNJAB",
  },
  {
    name: "Miss Faria Rubab",
    bps: "16",
    subjectTeaching: "Computer Science",
    lastDegree: "M.C.S",
    institutionAttended: "Bahria University",
    domicile: "PUNJAB",
  },
  {
    name: "Mrs. Kulsoom Anees",
    bps: "16",
    subjectTeaching: "Islamiat",
    lastDegree: "M.A(Islamiat)",
    institutionAttended: "Punjab University",
    domicile: "N.W.F.P",
  },
  {
    name: "Mrs. Fakhra Shabbir Jafferi",
    bps: "16",
    subjectTeaching: "ENGLISH",
    lastDegree: "M.A (English Literature & Language)",
    institutionAttended: "NUML",
    domicile: "PUNJAB",
  },
  {
    name: "Miss Sumaira Kanwal",
    bps: "9",
    subjectTeaching: "Library - Computer (Jr)",
    lastDegree: "M.L.I.S",
    institutionAttended: "A.I.O.U",
    domicile: "FEDERAL",
  },
  {
    name: "Miss Saima Shabbir",
    bps: "16",
    subjectTeaching: "G. Science/ Maths",
    lastDegree: "M.Sc (Education)",
    institutionAttended: "Arid Agriculture University",
    domicile: "FEDERAL",
    appointment: "14.11.07 (Daily wages)",
  },
  {
    name: "Mrs. Najia Naveed",
    bps: "16",
    subjectTeaching: "Social Studies / G.K",
    lastDegree: "MA Education",
    institutionAttended: "AIOU",
    domicile: "PUNJAB",
    appointment: "24-01-09",
  },
  {
    name: "Mrs. Nighat Yasmeen",
    bps: "16",
    subjectTeaching: "ENGLISH",
    lastDegree: "M.A (English Linguistic & Literature)",
    institutionAttended: "NUML",
    additionalDegree: "M.A (TEFL)",
    additionalInstitution: "A.I.O.U",
    professionalQualification:
      "Computer Courses, Diploma Homeopathic medical system, Acupuncture Specialist System",
    domicile: "PUNJAB",
    honoraryAppointment: "08.09.05 Honorary",
  },
];

// CDA Hospital Public Notices
export const publicNoticesModalSchool = [
  {
    date: "Oct 20, 2023 ",
    title: "Admission in Class-I is open.",
    badge: "Admission Notice",
    description:
      "Registration forms were made available for issuance from March 11, 2013 (Monday) to March 20, 2013 (Wednesday). The submission of these registration forms was accepted from March 13, 2013 (Wednesday) to March 22, 2013 (Friday). Call letters for the event were distributed from April 1, 2013 (Monday) to April 3, 2013 (Wednesday). The test and interview sessions were scheduled for April 8, 2013 (Monday) at 9:00 am and April 9, 2013 (Tuesday) at 9:00 am.",
  },

];
// school Gallery Data
export const galleryData = {
  "Chairman visit": [
    {
      name: "DSC_1.jpg",
      image: "Chairman_visit/DSC_1.jpg",
      link: "/public/Chairman_visit/DSC_1",
      type: "Chairman Visit",
    },
    {
      name: "DSC_2.jpg",
      image: "Chairman_visit/DSC_2.jpg",
      link: "/public/Chairman_visit/DSC_2",
      type: "Chairman Visit",
    },
    {
      name: "DSC_3.jpg",
      image: "Chairman_visit/DSC_3.jpg",
      link: "/public/Chairman_visit/DSC_3",
      type: "Chairman Visit",
    },
    {
      name: "DSC_4.jpg",
      image: "Chairman_visit/DSC_4.jpg",
      link: "/public/Chairman_visit/DSC_4",
      type: "Chairman Visit",
    },
    {
      name: "DSC_5.jpg",
      image: "Chairman_visit/DSC_5.jpg",
      link: "/public/Chairman_visit/DSC_5",
      type: "Chairman Visit",
    },
    {
      name: "DSC_6.jpg",
      image: "Chairman_visit/DSC_6.jpg",
      link: "/public/Chairman_visit/DSC_6",
      type: "Chairman Visit",
    },
    {
      name: "DSC_7.jpg",
      image: "Chairman_visit/DSC_7.jpg",
      link: "/public/Chairman_visit/DSC_7",
      type: "Chairman Visit",
    },
    {
      name: "DSC_8.jpg",
      image: "Chairman_visit/DSC_8.jpg",
      link: "/public/Chairman_visit/DSC_8",
      type: "Chairman Visit",
    },
    {
      name: "DSC_9.jpg",
      image: "Chairman_visit/DSC_9.jpg",
      link: "/public/Chairman_visit/DSC_9",
      type: "Chairman Visit",
    },
    {
      name: "DSC_10.jpg",
      image: "Chairman_visit/DSC_10.jpg",
      link: "/public/Chairman_visit/DSC_10",
      type: "Chairman Visit",
    },
    {
      name: "DSC_11.jpg",
      image: "Chairman_visit/DSC_11.jpg",
      link: "/public/Chairman_visit/DSC_11",
      type: "Chairman Visit",
    },
    {
      name: "DSC_12.jpg",
      image: "Chairman_visit/DSC_12.jpg",
      link: "/public/Chairman_visit/DSC_12",
      type: "Chairman Visit",
    },
    {
      name: "DSC_13.jpg",
      image: "Chairman_visit/DSC_13.jpg",
      link: "/public/Chairman_visit/DSC_13",
      type: "Chairman Visit",
    },
    {
      name: "DSC_14.jpg",
      image: "Chairman_visit/DSC_14.jpg",
      link: "/public/Chairman_visit/DSC_14",
      type: "Chairman Visit",
    },
    {
      name: "DSC_15.jpg",
      image: "Chairman_visit/DSC_15.jpg",
      link: "/public/Chairman_visit/DSC_15",
      type: "Chairman Visit",
    },
    {
      name: "DSC_16.jpg",
      image: "Chairman_visit/DSC_16.jpg",
      link: "/public/Chairman_visit/DSC_16",
      type: "Chairman Visit",
    },
    {
      name: "DSC_17.jpg",
      image: "Chairman_visit/DSC_17.jpg",
      link: "/public/Chairman_visit/DSC_17",
      type: "Chairman Visit",
    },
    {
      name: "DSC_18.jpg",
      image: "Chairman_visit/DSC_18.jpg",
      link: "/public/Chairman_visit/DSC_18",
      type: "Chairman Visit",
    },
    {
      name: "DSC_19.jpg",
      image: "Chairman_visit/DSC_19.jpg",
      link: "/public/Chairman_visit/DSC_19",
      type: "Chairman Visit",
    },
    {
      name: "DSC_20.jpg",
      image: "Chairman_visit/DSC_20.jpg",
      link: "/public/Chairman_visit/DSC_20",
      type: "Chairman Visit",
    },
    {
      name: "DSC_21.jpg",
      image: "Chairman_visit/DSC_21.jpg",
      link: "/public/Chairman_visit/DSC_21",
      type: "Chairman Visit",
    },
    {
      name: "DSC_22.jpg",
      image: "Chairman_visit/DSC_22.jpg",
      link: "/public/Chairman_visit/DSC_22",
      type: "Chairman Visit",
    },
    {
      name: "DSC_23.jpg",
      image: "Chairman_visit/DSC_23.jpg",
      link: "/public/Chairman_visit/DSC_23",
      type: "Chairman Visit",
    },
    {
      name: "DSC_24.jpg",
      image: "Chairman_visit/DSC_24.jpg",
      link: "/public/Chairman_visit/DSC_24",
      type: "Chairman Visit",
    },
    {
      name: "DSC_25.jpg",
      image: "Chairman_visit/DSC_25.jpg",
      link: "/public/Chairman_visit/DSC_25",
      type: "Chairman Visit",
    },
    {
      name: "DSC_26.jpg",
      image: "Chairman_visit/DSC_26.jpg",
      link: "/public/Chairman_visit/DSC_26",
      type: "Chairman Visit",
    },
    {
      name: "DSC_27.jpg",
      image: "Chairman_visit/DSC_27.jpg",
      link: "/public/Chairman_visit/DSC_27",
      type: "Chairman Visit",
    },
    {
      name: "DSC_28.jpg",
      image: "Chairman_visit/DSC_28.jpg",
      link: "/public/Chairman_visit/DSC_28",
      type: "Chairman Visit",
    },
    {
      name: "DSC_29.jpg",
      image: "Chairman_visit/DSC_29.jpg",
      link: "/public/Chairman_visit/DSC_29",
      type: "Chairman Visit",
    },
    {
      name: "DSC_30.jpg",
      image: "Chairman_visit/DSC_30.jpg",
      link: "/public/Chairman_visit/DSC_30",
      type: "Chairman Visit",
    },
    {
      name: "DSC_31.jpg",
      image: "Chairman_visit/DSC_31.jpg",
      link: "/public/Chairman_visit/DSC_31",
      type: "Chairman Visit",
    },
    {
      name: "DSC_32.jpg",
      image: "Chairman_visit/DSC_32.jpg",
      link: "/public/Chairman_visit/DSC_32",
      type: "Chairman Visit",
    },
    {
      name: "DSC_33.jpg",
      image: "Chairman_visit/DSC_33.jpg",
      link: "/public/Chairman_visit/DSC_33",
      type: "Chairman Visit",
    },
    {
      name: "DSC_34.jpg",
      image: "Chairman_visit/DSC_34.jpg",
      link: "/public/Chairman_visit/DSC_34",
      type: "Chairman Visit",
    },
    {
      name: "DSC_35.jpg",
      image: "Chairman_visit/DSC_35.jpg",
      link: "/public/Chairman_visit/DSC_35",
      type: "Chairman Visit",
    },
    {
      name: "DSC_36.jpg",
      image: "Chairman_visit/DSC_36.jpg",
      link: "/public/Chairman_visit/DSC_36",
      type: "Chairman Visit",
    },
  ],
  "14th August Gallery": [
    {
      name: "14 August 1",
      image: "14August1.jpeg",
      link: "/public/schoolGallery/14_August_001.jpeg",
      type: "14th August Gallery",
    },
    {
      name: "14 August 2",
      image: "14August2.jpeg",
      link: "/public/schoolGallery/14_August_002.jpeg",
      type: "14th August Gallery",
    },
    {
      name: "14 August 3",
      image: "14August3.jpeg",
      link: "/public/schoolGallery/14_August_003.jpeg",
      type: "14th August Gallery",
    },
    {
      name: "14 August 4",
      image: "14August4.jpeg",
      link: "/public/schoolGallery/14_August_004.jpeg",
      type: "14th August Gallery",
    },
    {
      name: "14 August 5",
      image: "14August5.jpeg",
      link: "/public/schoolGallery/14_August_005.jpeg",
      type: "14th August Gallery",
    },
    {
      name: "14 August 6",
      image: "14August6.jpeg",
      link: "/public/schoolGallery/14_August_006.jpeg",
      type: "14th August Gallery",
    },
  ],

  "Dental Checkup": [
    {
      name: "Dental Checkup 1",
      image: "DentalCheckup1.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 2",
      image: "DentalCheckup2.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 3",
      image: "DentalCheckup3.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 4",
      image: "DentalCheckup4.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 5",
      image: "DentalCheckup5.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 6",
      image: "DentalCheckup6.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 7",
      image: "DentalCheckup7.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 8",
      image: "DentalCheckup8.jpeg",
      link: "",
      type: "Dental Checkup",
    },
    {
      name: "Dental Checkup 9",
      image: "DentalCheckup9.jpeg",
      link: "",
      type: "Dental Checkup",
    },
  ],

  "Annual Day": [
    {
      name: "Annual Day 1",
      image: "Annual_Day_000.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 2",
      image: "Annual_Day_001.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 3",
      image: "Annual_Day_002.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 4",
      image: "Annual_Day_003.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 5",
      image: "Annual_Day_004.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 6",
      image: "Annual_Day_005.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 7",
      image: "Annual_Day_006.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 8",
      image: "Annual_Day_007.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 9",
      image: "Annual_Day_008.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 10",
      image: "Annual_Day_009.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 11",
      image: "Annual_Day_010.jpeg",
      link: "",
      type: "Annual Day",
    },
    {
      name: "Annual Day 12",
      image: "Annual_Day_011.jpeg",
      link: "",
      type: "Annual Day",
    },
  ],

  "Interhouse Competion": [
    {
      name: "Interhouse Compitition 1",
      image: "InterhouseCompitition1.jpeg",
      link: "",
      type: "Interhouse Competion",
    },
    {
      name: "Interhouse Compitition 2",
      image: "InterhouseCompitition2.jpeg",
      link: "",
      type: "Interhouse Competion",
    },
    {
      name: "Interhouse Compitition 3",
      image: "InterhouseCompitition3.jpeg",
      link: "",
      type: "Interhouse Competion",
    },
    {
      name: "Interhouse Compitition 4",
      image: "InterhouseCompitition4.jpeg",
      link: "",
      type: "Interhouse Competion",
    },
    {
      name: "Interhouse Compitition 5",
      image: "InterhouseCompitition5.jpeg",
      link: "",
      type: "Interhouse Competion",
    },
  ],

  "Investiture Ceremony": [
    {
      name: "Investiture Ceremony 1",
      image: "Investiture_ceremony_001.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 2",
      image: "Investiture_ceremony_002.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      id: 133,
      name: "Investiture Ceremony 3",
      image: "Investiture_ceremony_003.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 4",
      image: "Investiture_ceremony_004.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 5",
      image: "Investiture_ceremony_005.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 6",
      image: "Investiture_ceremony_006.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 7",
      image: "Investiture_ceremony_007.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      id: 133,
      name: "Investiture Ceremony 8",
      image: "Investiture_ceremony_008.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 9",
      image: "Investiture_ceremony_009.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 10",
      image: "Investiture_ceremony_010.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 11",
      image: "Investiture_ceremony_011.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      name: "Investiture Ceremony 12",
      image: "Investiture_ceremony_012.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
    {
      id: 133,
      name: "Investiture Ceremony 13",
      image: "Investiture_ceremony_013.jpeg",
      link: "",
      type: "Investiture Ceremony",
    },
  ],

  "Panah Seminar": [
    {
      name: "Panah Seminar 1",
      image: "Panah_Seminar (1).jpg",
      link: "",
      type: "Panah Seminar",
    },
    {
      name: "Panah Seminar 2",
      image: "Panah_Seminar_001.jpeg",
      link: "",
      type: "Panah Seminar",
    },
    {
      name: "Panah Seminar 3",
      image: "Panah_Seminar_002.jpeg",
      link: "",
      type: "Panah Seminar",
    },
    {
      name: "Panah Seminar 4",
      image: "Panah_Seminar_003.jpeg",
      link: "",
      type: "Panah Seminar",
    },
  ],

  "School Labs": [
    {
      name: "School Labs 1",
      image: "School_Lab_001.jpeg",
      type: "School Labs",
    },
    {
      name: "School Labs 2",
      image: "School_Lab_002.jpeg",
      link: "",
      type: "School Labs",
    },
    {
      name: "School Labs 3",
      image: "School_Lab_003.jpeg",
      link: "",
      type: "School Labs",
    },
    {
      name: "School Labs 4",
      image: "School_Lab_004.jpeg",
      link: "",
      type: "School Labs",
    },
    {
      name: "School Labs 5",
      image: "School_Lab_005.jpeg",
      link: "",
      type: "School Labs",
    },
  ],

  "Sports Day": [
    {
      name: "Sports Day 1",
      image: "Sports_Day_001.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 2",
      image: "Sports_Day_002.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 3",
      image: "Sports_Day_003.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 4",
      image: "Sports_Day_004.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 5",
      image: "Sports_Day_005.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 6",
      image: "Sports_Day_006.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 7",
      image: "Sports_Day_007.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 8",
      image: "Sports_Day_008.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 9",
      image: "Sports_Day_009.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 10",
      image: "Sports_Day_010.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 11",
      image: "Sports_Day_011.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 12",
      image: "Sports_Day_012.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 13",
      image: "Sports_Day_013.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 14",
      image: "Sports_Day_014.jpeg",
      link: "",
      type: "Sports Day",
    },
    {
      name: "Sports Day 15",
      image: "Sports_Day_015.jpeg",
      link: "",
      type: "Sports Day",
    },
  ],

  "Result 2009": [
    {
      name: "Result 2009 1",
      image: "ALIM1277.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 2",
      image: "ALIM1292.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 3",
      image: "ALIM1293.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 4",
      image: "ALIM1296.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 5",
      image: "ALIM1305.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 6",
      image: "ALIM1307.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 7",
      image: "ALIM1309.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 8",
      image: "ALIM1310.jpeg",
      link: "",
      type: "Result 2009",
    },
    {
      name: "Result 2009 9",
      image: "ALIM1313.jpeg",
      link: "",
      type: "Result 2009",
    },
  ],

  "Eid Mela": [
    {
      name: "Eid Mela 1",
      image: "Eid_Mela_001.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 2",
      image: "Eid_Mela_002.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 3",
      image: "Eid_Mela_003.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 4",
      image: "Eid_Mela_004.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 5",
      image: "Eid_Mela_005.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 6",
      image: "Eid_Mela_006.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 7",
      image: "Eid_Mela_007.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 8",
      image: "Eid_Mela_008.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 9",
      image: "Eid_Mela_009.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 10",
      image: "Eid_Mela_010.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 11",
      image: "Eid_Mela_011.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 12",
      image: "Eid_Mela_012.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 13",
      image: "Eid_Mela_013.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 14",
      image: "Eid_Mela_014.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 15",
      image: "Eid_Mela_015.jpeg",
      link: "",
      type: "Eid Mela",
    },
    {
      name: "Eid Mela 16",
      image: "Eid_Mela_016.jpeg",
      link: "",
      type: "Eid Mela",
    },
  ],

  "Annual Prize Distribution": [
    {
      name: "Annual Prize Distribution 1",
      image: "ALIM1055.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 2",
      image: "ALIM1060.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 3",
      image: "ALIM1062.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 4",
      image: "ALIM1065.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 5",
      image: "ALIM1066.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 6",
      image: "ALIM1068.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 7",
      image: "ALIM1084.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 8",
      image: "ALIM1086.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 9",
      image: "ALIM1094.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 10",
      image: "ALIM1117.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 11",
      image: "ALIM1120.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 12",
      image: "ALIM1167.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 13",
      image: "ALIM1171.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 14",
      image: "ALIM1174.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 15",
      image: "ALIM1186.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 16",
      image: "ALIM1202.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
    {
      name: "Annual Prize Distribution 17",
      image: "ALIM1230.jpg",
      link: "",
      type: "Annual Prize Distribution",
    },
  ],

  "Result Day": [
    {
      name: "Result Day 1",
      image: "Result_Day_001.jpeg",
      link: "",
      type: "Result Day",
    },
    {
      name: "Result Day 2",
      image: "Result_Day_002.jpeg",
      link: "",
      type: "Result Day",
    },
    {
      name: "Result Day 3",
      image: "Result_Day_003.jpeg",
      link: "",
      type: "Result Day",
    },
    {
      name: "Result Day 4",
      image: "Result_Day_004.jpeg",
      link: "",
      type: "Result Day",
    },
    {
      name: "Result Day 5",
      image: "Result_Day_005.jpeg",
      link: "",
      type: "Result Day",
    },
  ],
};

// zone 1 data
export const zoneI = [
  { number: 1, location: "Al Rayan Society" },
  { number: 2, location: "Ali Town" },
  { number: 3, location: "Arcadia City" },
  { number: 4, location: "Aryan Enclave, Korang road, Banni Galla" },
  {
    number: 5,
    location:
      "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River",
  },
  {
    number: 6,
    location:
      "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River",
  },
  {
    number: 7,
    location:
      "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River",
  },
  {
    number: 8,
    location:
      "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River",
  },
  { number: 9, location: "Green Meadows (North Ridge) Housing Scheme" },
  { number: 10, location: "Al Rayan Society" },
  { number: 11, location: "Ali Town" },
  { number: 12, location: "Arcadia City" },
  { number: 13, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 14, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 15, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 17, location: "Green Meadows (North Ridge) Housing Scheme" },
  { number: 18, location: "Al Rayan Society" },
  { number: 19, location: "Ali Town" },
  { number: 20, location: "Arcadia City" },
  { number: 21, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 22, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 23, location: "Aryan Enclave, Korang road, Banni Galla" },
  { number: 24, location: "Aryan Enclave, Korang road, Banni Galla" },
];
export const ZoneII = [
  "Al Rayan Society",
  "Green Meadows (North Ridge) Housing Scheme",
  "Ali Town",
  "Al Rayan Society",
  "Arcadia City",
  "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River",
  "Aryan Enclave, Korang road, Banni Galla",
];
//locations
export const locations = [
  {
    name: "Capital Hospital",
    latitude: 33.6496056,
    longitude: 72.9002275,
  },
  {
    name: "Audit & Accounts Directorate",
    latitude: 33.7146305,
    longitude: 73.0663183,
  },
  {
    name: "Sanitation Directorate",
    latitude: 33.710275,
    longitude: 73.0816999,
  },
  {
    name: "CDA Magistrate office, Revenue Directorate",
    latitude: 33.7092982,
    longitude: 73.0553374,
  },
  {
    name: "CDA Facilitation Centre",
    latitude: 33.7280709,
    longitude: 72.9920457,
  },
  {
    name: "Capital Development Authority Secretariat",
    latitude: 33.7023402,
    longitude: 73.0752121,
  },
  {
    name: "Fire Brigade Head Quarter",
    latitude: 33.6996087,
    longitude: 73.0729706,
  },
  {
    name: "Environment Directorate",
    latitude: 33.6964437,
    longitude: 72.946817,
  },
];

export const contactUs = [
  {
    title: "Chairman Office, CDA Secretariat",
    location: "Khayaban-e-Suharwardi, Sector G-7/4, Islamabad",
    time: "08:30-16:30",
    day: "Monday-Friday",
  },
  {
    title: "Public Information Officer, CDA",
    location: "Public Relation Directorate, CDA",
    time: "CDA Secretariat, Block-V, Sector G-7/4, Islamabad",
    day: "Tel: +92-51-9252614",
  },
];
// phoneDirectory
export const phoneDirectory = [
  "Chairman Office, CDA Secretariat",
  "Administration Wing",
  "Civic Management Wing",
  "Directorate of Municipal Administration (DMA)",
  "Hospital & CARES",
  "Sports & Culture",
  "Planning Wing",
  "Law Directorate",
  "Design Wing",
  "Building Control",
  "Structure",
  "Housing Societies",
  "Estate Management",
  "Finance Wing",
  "Accounts, Revenue and Treasury",
  "Procurement and Municipal Bond",
  "Information Technology (IT)",
  "Environment Wing",
  "Parks and Zoo",
  "Engineering Wing",
  "Works & QS",
  "Sector Development",
  "Services & Maintenance",
  "Water Management",
  "Electrical & Mechanical",
  "Programming & Evaluation",
  "Estate Wing",
  "(Land & Rehabilitation) and Enforcement",
  "Labour Relations & One Window Operations",
  "DC & Magistrate",
  "Water Tanker Enquiry",
  "Street Light Enquiry",
  "CDA Enquiry Nos.",
];

// chairman directory
export const chairman = [
  {
    office: "Chairman",
    phone: "9253001",
    ext: "201",
    fax: "9253009",
    email: "chairman@cda.gov.pk",
  },
  {
    office: "PS to Chairman",
    phone: "9253003",
    ext: "220",
    fax: "",
    email: "",
  },
  {
    office: "Director/ Staff Officer to Chairman",
    phone: "9252616",
    ext: "207",
    fax: "9252113",
    email: "dir.staff@cda.gov.pk",
  },
  {
    office: "Secretary CDA Board",
    phone: "9252972",
    ext: "254",
    fax: "9252972",
    email: "",
  },
  {
    office: "Director (Security)",
    phone: "9252994",
    ext: "284",
    fax: "9252431",
    email: "",
  },
  {
    office: "Director Coord",
    phone: "9252961",
    ext: "217",
    fax: "",
    Email: "",
  },
  {
    office: "Dy. Dir. (Protocol)",
    phone: "9253007",
    ext: "208",
    fax: "",
    email: "",
  },
  {
    office: "PA to Chairman",
    phone: "9230274",
    ext: "269",
    fax: "",
    email: "",
  },
];

//cda inquiry Nos.
export const inquiryNos = [
  {
    location: "Flood Relief Cell",
    phone: "2855513",
  },
  {
    location: "F-6/1 Sector",
    phone: "9204955",
  },
  {
    location: "F-6/3 Sector",
    phone: "9205111",
  },
  {
    location: "F-6/4 Sector",
    phone: "9202690",
  },
  {
    location: "F-7 Sector",
    phone: "9202043",
  },
  {
    location: "F-8 Sector",
    phone: "2262475 / 9202043",
  },
  {
    location: "F-10 Sector",
    phone: "9266424",
  },
  {
    location: "G-6/4 Sector",
    phone: "9204668",
  },
  {
    location: "G-6/2 Sector",
    phone: "9204672",
  },
  {
    location: "G-7/1 Sector",
    phone: "9205073",
  },
  {
    location: "G-7/2 Sector",
    phone: "9205098",
  },
  {
    location: "G-7/3 Sector",
    phone: "9205042",
  },
  {
    location: "G-7/4 Sector",
    phone: "9204713",
  },
  {
    location: "G-8 Sector",
    phone: "9260261",
  },
  {
    location: "G-9 Sector",
    phone: "9262357",
  },
  {
    location: "I-8 Sectors",
    phone: "9290295",
  },
  {
    location: "R&T CDA (Sectt.)",
    phone: "",
  },
];

// street light nos
export const streetLightNos = [
  {
    location: "G-6, G-7, G-5, F-5, F-6, E-7, F-7, Park Road, Kashmir Highway",
    phone: "9208837",
  },
  {
    location:
      "I-8, I-9, I-10, I-10, I-11, H-8, H-9, H-11, G-8, G-9, G-11, F-10, F-11",
    phone: "9101266",
  },
];
/// wanter tank Nos

export const waterTankerEnquiry = [
  {
    location:
      "G-5, G-6, G-7, F-6, F-5, Diplomatic Enclave, Margalla Town, Rawal Town, Ali Pur, Shahzad Town",
    phone: "9209680",
  },
  {
    location: "I-9, I-8, I-10, I-11, I-12",
    phone: "9257310 / 9257304",
  },
  {
    location: "G-8, G-9, G-10, G-10 (Headwork), G-11, G-12",
    phone: "9266316 / 9106085",
  },
  {
    location: "F-7, F-8, F-10, F-11, E-7",
    phone: "9202218 / 2852807",
  },
  {
    location: "F-8 Tanker Enquiry",
    phone: "2817149",
  },
  {
    location: "G-6 Water Meter",
    phone: "9202109",
  },
];

///
export const administrationDirectory = [
  {
    office: "Member Administration",
    phone: 9252638,
    ext: 204,
    fax: 9252612,
    email: "",
  },
  {
    office: "S.O to Member Admin",
    phone: 9252638,
    ext: 209,
    fax: "",
    email: "",
  },
  { office: "DG (Admin)", phone: 9253012, ext: 250, fax: 9253014, email: "" },
  { office: "DDG HRD", phone: 9252980, ext: 230, fax: "" },
  {
    office: "Director (Admin)",
    phone: 9253015,
    ext: 252,
    fax: "",
    email: "",
  },
  { office: "Director HRD-I", phone: 9252965, ext: 231, fax: "", email: "" },
  { office: "Director HRD-II", phone: 9252959, ext: 233, fax: "", email: "" },
];

// civic management wing phone directory
export const civicManagementDirectory = [
  {
    office: "DG (Civic Mang)",
    phone: "9203216",
  },
  {
    office: "Director (Sanitation)",
    phone: "9211555",
    fax: "9222555",
  },
];

// dma phone directory
export const dmaDirectory = [
  {
    office: "DDG (DMA)",
    phone: "9252838",
    ext: "",
    fax: "9252844",
  },
  {
    office: "Director MA",
    phone: "9252838",
    ext: "",
    fax: "9252844",
  },
  {
    office: "Director (E&DM)",
    phone: "9253197/9253215",
    ext: "16",
    fax: "9253216",
  },
  {
    office: "Director (P.R)",
    phone: "9252614",
    ext: "277",
    fax: "9252492",
  },
];

// hospital & cares phone directory
export const hospitalAndCaresDirectory = [
  {
    office: "Executive Director (Hospital)",
    phone: "9212376",
    ext: "276",
    fax: "9224377",
  },
  {
    office: "Director Admin/Medical",
    phone: "9204044",
    ext: "328",
    fax: "9204542",
  },
  {
    office: "Director Health Services",
    phone: "9267580",
    ext: "",
    fax: "9267581",
  },
  {
    office: "Director Cares Ambulance",
    phone: "9221263",
    ext: "270",
    fax: "9252492",
  },
];

// Sports and Culture phone directory
export const sportsAndCultureDirectory = [
  {
    office: "Director (Sport & Culture)",
    phone: "9252967",
  },
];

// hospital & cares phone directory
export const planningDirectory = [
  {
    office: "Member (Planning)",
    phone: "9252620",
    ext: "216",
    fax: "9252621",
    email: "member.planning@cda.gov.pk",
  },
  {
    office: "SO to Member (P&D)",
    phone: "9252620",
    ext: "221",
    fax: "9204542",
    email: "",
  },
  {
    office: "D G Planning",
    phone: "9252613",
    ext: "242",
    fax: "9267581",
    email: "",
  },
  {
    office: "DDG (Planning)",
    phone: "9252605",
    ext: "",
    fax: "",
    email: "",
  },
  {
    office: "DDG(Planning)",
    phone: "9252473 9252930",
    ext: "270",
    fax: "",
    email: "",
  },
  {
    office: "Dir(Regional Planning)",
    phone: "9252605",
    ext: "214",
    fax: "",
    email: "",
  },
  {
    office: "Director(Urban Planning)",
    phone: "9252495",
    ext: "253",
    fax: "",
    email: "",
  },
];

// law directorate phone directory
export const lawDirectorateDirectory = [
  {
    office: "DG (Law)",
    phone: "9252986",
    ext: "260",
  },
  {
    office: "DDG (Law)",
    phone: "9252421",
    ext: "262",
  },
  {
    office: "Director Law-I",
    phone: "9252609",
    ext: "",
  },
  {
    office: "Director Law Opinion",
    phone: "9252421",
    ext: "270",
  },
];

// design wing phone directory
export const designWingDirectory = [
  {
    office: "DG (Design)",
    phone: "9252986",
    ext: "280",
    fax: "9253159",
  },
  {
    office: "DDG (Architecture)DG (Law)",
    phone: "9252421",
    ext: "255",
    fax: "",
  },
  {
    office: "Director (Arch)",
    phone: "9252991",
    ext: "",
    fax: "",
  },
];

// bulding control phone directory
export const buildingControlDirectory = [
  {
    office: "DDG. (Building Control)",
    phone: "9252112",
  },
  {
    office: "Dir. (Building Control)",
    phone: "9240989",
  },
];

// structure phone directory
export const structureDirectory = [
  {
    office: "DG(Structure)",
    phone: "9253160",
    ext: "280",
    fax: "9253159",
  },
  {
    office: "Structure(Services)",
    phone: "9252634",
    ext: "",
    fax: "",
  },
  {
    office: "Structure(Building)",
    phone: "9252476",
    ext: "",
    fax: "",
  },
  {
    office: "Director Structure(Road)",
    phone: "9252963",
    ext: "",
    fax: "",
  },
  {
    office: "Director (Structure)",
    phone: "9252637",
    ext: "",
    fax: "",
  },
];

// housing societies traffic engineering phone directory
export const housingSocietiesDirectory = [
  {
    office: "Director (Housing Societies)",
    phone: "9252983",
    ext: "214",
  },
  {
    office: "Dir. (Traffic Engg & Trans)",
    phone: "9252604",
    ext: "",
  },
];

// finance wing phone directory
export const financeWingDirectory = [
  {
    office: "FA / Member",
    phone: "9253010",
    ext: "202",
    fax: "9253011",
    email: "memberfinance@cda.gov.pk",
  },
  {
    office: "SO to FA/Member",
    phone: "9239096",
    ext: "210",
    fax: "",
    email: "",
  },
  {
    office: "DDG (Finance)/ DFA-II",
    phone: "9253020",
    ext: "246",
    fax: "9253109",
    email: "",
  },
  {
    office: "DFA-1",
    phone: "9252969",
    ext: "237",
    fax: "9252052",
    email: "",
  },
];

// accounts, revenue and treasury phone directory
export const accountsRevenueTreasuryDirectory = [
  {
    office: "DDG (Accounts)",
    phone: "9201120",
    fax: "",
  },
  {
    office: "Director (Audit)",
    phone: "9201120",
    fax: "9207075",
  },
  {
    office: "Director (Revenue)",
    phone: "9252331",
    fax: "9252385",
  },
  {
    office: "Head of Treasury",
    phone: "9252408",
    fax: "",
  },
];

// procurement and municipal bond phone directory
export const procurementAndMunicipalDirectory = [
  {
    office: "Director Procurement",
    phone: "2890344",
  },
  {
    office: "PD Municipal Bond",
    phone: "9252992",
  },
];

// information technology phone directory
export const informationTechnologyDirectory = [
  {
    office: "Director IT",
    phone: "9240036",
  },
];

// environment wing phone directory
export const environmentWingDirectory = [
  {
    office: "Member (Environment)",
    phone: "9252606",
    ext: "257",
    email: "member.environment@cda.gov.pk",
  },
  {
    office: "SO to Member(Environment)",
    phone: "9252606",
    ext: "241",
    email: "",
  },
  {
    office: "D.G (Environment)",
    phone: "9261786",
    ext: "",
    email: "",
  },
  {
    office: "DDG(Envt-I)",
    phone: "9260412",
    ext: "",
    email: "",
  },
  {
    office: "DDG(Envt-II)",
    phone: "9262457/9262129",
    ext: "",
    email: "memberfinance@cda.gov.pk",
  },
  {
    office: "Dir. Environment (East)",
    phone: "9262457",
    ext: "",
    email: "",
  },
  {
    office: "Director Environment (West)",
    phone: "9260595",
    ext: "",
    email: "",
  },
  {
    office: "Director Envt. Protection Cell",
    phone: "9240485",
    ext: "",
    email: "",
  },
];

// parks and zoos phone directory
export const parkAndZoosDirectory = [
  {
    office: "Director Parks (East)",
    phone: "9263194",
    fax: "9263194",
  },
  {
    office: "Director Zoo & Wildlife",
    phone: "9243439/9205644",
    fax: "9205644",
  },
];

// parks and zoos phone directory
export const engineeringWingDirectory = [
  {
    office: "Member (Engineering)",
    phone: "9252970",
    ext: "203",
    fax: "9252996",
    email: "member.engineering@cda.gov.pk",
  },
  {
    office: "SO to Member Engg",
    phone: "9253065",
    ext: "211",
    fax: "9205644",
    email: "",
  },
];

// work and qs phone directory
export const workAndQsDirectory = [
  {
    office: "DG (Works)",
    phone: "9202095",
  },
  {
    office: "Director Works",
    phone: "9203773",
  },
  {
    office: "Director Q.S",
    phone: "9242641",
  },
  {
    office: "Director Road (North)",
    phone: "9205413",
  },
  {
    office: "Director Road (South)",
    phone: "9212674",
  },
  {
    office: "Director Road & Market (Maint)",
    phone: "9202315",
  },
];

// sector development phone directory
export const sectorDevelopmentDirectory = [
  {
    office: "Director I-15 & BWM",
    phone: "9243424 9206903",
  },
  {
    office: "Director (Sector Development)",
    phone: "9204887",
  },
  {
    office: "P.D ZPIP, Lehtrar Road & P/Enclave",
    phone: "",
  },
  {
    office: "P.D K/Highway",
    phone: "9209538",
  },
  {
    office: "P.D (BIC)",
    phone: "9213645",
  },
];

// services and maintainance phone directory
export const serviceAndMaintainanceDirectory = [
  {
    office: "D.G (Services)",
    phone: "9205415",
    fax: "9206058",
  },
  {
    office: "Director (Maintenance)",
    phone: "9209163",
    fax: "",
  },
  {
    office: "Director (Aiwan-E-Sadr)",
    phone: "9220817 90202315",
    fax: "",
  },
  {
    office: "Dir. Parliament Lodges",
    phone: "9208656",
    fax: "",
  },
  {
    office: "Director Landfill",
    phone: "",
    fax: "",
  },
];

// water management phone directory
export const waterManagementDirectory = [
  {
    office: "DG Water Management",
    phone: "9203724",
    ext: "203",
    fax: "9207064",
  },
  {
    office: "DDG (Water Supply)",
    phone: "9244756",
    ext: "",
    fax: "",
  },
  {
    office: "Director Water",
    phone: "9202207",
    ext: "",
    fax: "",
  },
  {
    office: "Director Bulk Water Managmt(Khan Pur & Simly Dam)",
    phone: "9208663",
    ext: "",
    fax: "",
  },
  {
    office: "Director (Water & Sewerage)",
    phone: "9201013",
    ext: "",
    fax: "",
  },
  {
    office: "Director STP",
    phone: "9257769",
    ext: "",
    fax: "",
  },
];

// electrical and mechanical phone directory
export const electricalAndMechanicalDirectory = [
  {
    office: "D.G (E & M)",
    phone: "9218957",
  },
  {
    office: "Director E & M (Dev.)",
    phone: "9209886",
  },
  {
    office: "Director E & M (Maintenance)",
    phone: "9209317",
  },
  {
    office: "Director MPO",
    phone: "9241679",
  },
];

// programming and evaluation phone directory
export const programmingAndEvaluationDirectory = [
  {
    office: "DG (P&E)",
    phone: "9252930",
    ext: "270",
    fax: "9253102",
  },
];

// estate wing phone directory
export const estateWingDirectory = [
  {
    office: "Member (Estate)",
    phone: "9252648",
    ext: "215",
    fax: "9252646",
    email: "member.estate@cda.gov.pk",
  },
  {
    office: "PS to Member Estate",
    phone: "9252648",
    ext: "259",
    fax: "",
    email: "",
  },
];

// estate management phone directory
export const estateManagementDirectory = [
  {
    office: "Director E.M-I (East)",
    phone: "9252428",
    ext: "264",
    fax: "9252428",
  },
  {
    office: "Director E.M-I (West)",
    phone: "9252428",
    ext: "264",
    fax: "9252973",
  },
  {
    office: "Director E.M-II",
    phone: "9252481",
    ext: "285",
    fax: "",
  },
];

// (LAND & REHABILITATION) AND ENFORCEMENT phone directory
export const landAndRehabAndEnforcementDirectory = [
  {
    office: "Director (Land & Rehabilitation)",
    phone: "9252997",
    ext: "274",
  },
  {
    office: "Director (Enforcement)",
    phone: "9252998",
    ext: "275",
  },
];

// labour relation and one window operation phone directory
export const labourRelationAndOneWindowOperationDirectory = [
  {
    office: "DDG (L R)",
    phone: "9252618",
    ext: "258",
  },
  {
    office: "Director (OWO)",
    phone: "9252945",
    ext: "",
  },
];

// DC & magistrate phone directory
export const dcAndMagistrateDirectory = [
  {
    office: "DC CDA",
    phone: "9252418",
  },
  {
    office: "Sr. Special Magistrate",
    phone: "9252822",
  },
];

///
export const commercialProjects = [
  {
    serialNumber: 1,
    projectTitle: "aBcDeFgHiJ",
    plotNo: 73,
    location: "XyZ12345",
    society: "SocietyABC",
    natureofCase: "LegalDispute",
    status: "Pending",
    coveredArea: 1354.78,
  },
  {
    serialNumber: 2,
    projectTitle: "KlMnOpQrSt",
    plotNo: 42,
    location: "AbCdEfGh",
    society: "SocietyXYZ",
    natureofCase: "ConstructionIssue",
    status: "InProgress",
    coveredArea: 987.23,
  },
  {
    serialNumber: 3,
    projectTitle: "uVwXyZaBc",
    plotNo: 56,
    location: "MnOpQrSt",
    society: "Society456",
    natureofCase: "ZoningViolation",
    status: "Completed",
    coveredArea: 1200.45,
  },

  {
    serialNumber: 30,
    projectTitle: "HgFeDcBa",
    plotNo: 18,
    location: "LkJiHgF",
    society: "Society789",
    natureofCase: "PermitApproval",
    status: "Pending",
    coveredArea: 1605.67,
  },
];

//
export const buildingProject = [
  {
    serialNumber: 31,
    projectTitle: "BaCdEfGhIj",
    plotNo: 22,
    location: "RtYyUuIi",
    society: "SocietyXYZ",
    natureofCase: "LandEncroachment",
    status: "InProgress",
    coveredArea: 800.56,
  },
  {
    serialNumber: 32,
    projectTitle: "QwErTyUyZ",
    plotNo: 89,
    location: "HgFdSaQw",
    society: "Society789",
    natureofCase: "BuildingCodeViolation",
    status: "Pending",
    coveredArea: 1125.78,
  },
  {
    serialNumber: 33,
    projectTitle: "ZxYvUtSrQ",
    plotNo: 45,
    location: "PlKjHgFd",
    society: "SocietyABC",
    natureofCase: "EnvironmentalConcern",
    status: "Completed",
    coveredArea: 1500.23,
  },

  {
    serialNumber: 60,
    projectTitle: "NjHgFbVcX",
    plotNo: 17,
    location: "MkLoPqRs",
    society: "SocietyXYZ",
    natureofCase: "LegalDispute",
    status: "InProgress",
    coveredArea: 900.34,
  },
];

// Doctor Schedule
export const doctorSchedule = [
  {
    name: "General Surgery",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
  {
    name: "Gyane",
    opdDays: [0, 1, 0, 1, 1, 1, 0],
    responsive_Opd_Days: ["Tue", "Thurs-Sat"],
  },
  {
    name: "Urology",
    opdDays: [0, 1, 0, 1, 0, 1, 0],
    responsive_Opd_Days: ["Tue", "Thurs", "Sat"],
  },
  {
    name: "Orthopedics",
    opdDays: [1, 0, 0, 1, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Thurs", "Fri"],
  },
  {
    name: "ENT",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
  {
    name: "Opthalmology",
    opdDays: [0, 1, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Tue", "Wed", "Fri"],
  },
  {
    name: "Dermatology",
    opdDays: [1, 0, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily except Tuesday"],
  },
  {
    name: "General Medicine (Male , Female)",
    opdDays: [1, 1, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily"],
  },
  {
    name: "Cardiology",
    opdDays: [0, 1, 1, 0, 0, 1, 0],
    responsive_Opd_Days: ["Tue", "Wed", "Sat"],
  },
  {
    name: "Medicine (Dr Farhan)",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
  {
    name: "Psychiatry",
    opdDays: [1, 1, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily"],
  },
  {
    name: "Medicine (Dr Azizun Nisa)",
    opdDays: [0, 1, 0, 1, 0, 1, 0],
    responsive_Opd_Days: ["Tue", "Thurs", "Sat"],
  },
  {
    name: "Peadiatrics",
    opdDays: [1, 1, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily"],
  },
  {
    name: "Peadiatrics Surgery",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
  {
    name: "Nephrology",
    opdDays: [1, 1, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily"],
  },
  {
    name: "Dental",
    opdDays: [1, 1, 1, 1, 1, 1, 1],
    responsive_Opd_Days: ["Daily"],
  },
  {
    name: "Spinal",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
  {
    name: "Neuro Surgery",
    opdDays: [0, 1, 0, 1, 1, 0, 0],
    responsive_Opd_Days: ["Tue", "Thurs", "Fri"],
  },
  {
    name: "Cardiac Surgery",
    opdDays: [1, 1, 1, 1, 0, 0, 0],
    responsive_Opd_Days: ["Mon-Thurs"],
  },
  {
    name: "Endocrinology",
    opdDays: [1, 0, 0, 1, 0, 0, 0],
    responsive_Opd_Days: ["Mon", "Thurs"],
  },
  {
    name: "Cochlear Impant",
    opdDays: [0, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Wed", "Fri"],
  },
  {
    name: "Plastic Surgery",
    opdDays: [1, 0, 1, 0, 1, 0, 0],
    responsive_Opd_Days: ["Mon", "Wed", "Fri"],
  },
];

// housing Societies data

export const housingSocieties = [
  {
    Name: "AGOCHS, Phase-I",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "AGOCHS, Phase-II",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Al-Hamra Avenue",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Al-Hamra Hills Agro Farming Scheme",
    LOP: "Approved",
    NOC: "Cancelled",
  },
  {
    Name: "Al-Makkah City",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Anza Zephyr Dale Agro Farms",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Army Welfare Trust",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Bahria Enclave Phase-I Housing Scheme",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Bahria Enclave-II, Agro Farming Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Bahria Enclave-II, Phase-II, Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Bahria Garden City",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Bahria Town (Phase-III-E & IV)",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Bahria Town (Phase-VII-E)",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Bahria Town, Phase VII",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Bahria Town, Phase-II,III,V & VI",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Cabinet Division Employees Co-operative Housing Society",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Capital Enclave",
    LOP: "Provisional Approval",
    NOC: "Not Issued",
  },
  {
    Name: "CBR Town",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Engineers Co-operative",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Engineers Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Federal Government Employees Housing Foundation (FGEHF)",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Federation of Employees",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "FIA Park Enclave Housing Scheme",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Grace Valley",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Gulberg Greens Farm Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Gulberg Residencia (IBECHS Ph-III) Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Gulshan-e-Rabia",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Gulshan-e-Sehat",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Islamabad Gardens",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Islamabad Model Town",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Jeddah Town",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Jinnah Garden Phase-1 Extension",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Jinnah Garden, Phase-I",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Jinnah Garden, Phase-II",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Jinnah Town",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Kashmir Gardens Farming Scheme",
    LOP: "Provisional Approval",
    NOC: "Not Issued",
  },
  {
    Name: "Khayaban-e-Kashmir, Phase (Extension)",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Khayaban-e-Kashmir, Phase-I",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Khayaban-e-Kashmir, Phase-II",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Margalla View Housing Scheme",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Ministry of Interior Employees Co-operative Housing Society",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Morgah City",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Multi Gardens, Phase-I",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Multi Gardens, Phase-II",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "National Assembly Empolyees Cooperative Housing Society (NAECHS)",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "National Cooperative Housing Society & New Islamabad Enclave",
    LOP: "Approved",
    NOC: "not Issued",
  },
  {
    Name: "National Police Foundation",
    LOP: "Approved",
    NOC: "Cancelled",
  },
  {
    Name: "Naval Anchorage",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "New Islamabad Garden",
    LOP: "Approved",
    NOC: "Cancelled",
  },
  {
    Name: "OPF Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Pakistan Medical Cooperative Housing Scheme",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Pakistan Navy Farms, Simly Dam Road, Islamabad",
    LOP: "Issued",
  },
  {
    Name: "Paradise City",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Park View City Housing Scheme",
    LOP: "Approved",
    NOC: "Approved",
  },
  {
    Name: "Parliamentarians Enclave",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Rahman Enclave Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "River Garden",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Roshan Pakistan (RP) Corporation Housing Scheme",
    LOP: "Approved",
    NOC: "Cancelled",
  },
  {
    Name: "Senate Avenue",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Services Co-operative Housing Society",
    LOP: "Cancelled",
    NOC: "Not Issued",
  },
  {
    Name: "Soan Gardens",
    LOP: "Approved",
    NOC: "Cancelled",
  },
  {
    Name: "Supreme Court Employees Housing Scheme",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "T&T Employees Cooperative Housing Society (Tele Gardens)",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "Tele Gardens Housing Scheme, F-17, Islamabad",
    LOP: "Approved",
    NOC: "Issued",
  },
  {
    Name: "WWF Labor Colony",
    LOP: "Approved",
    NOC: "Not Issued",
  },
  {
    Name: "Zaraj Housing",
    LOP: "Approved",
    NOC: "Issued",
  },
];

//countries
export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const countriesDialcode =
[
  {
  "name": "Afghanistan",
  "dial_code": "93",
  "code": "AF"
  },
  {
  "name": "Aland Islands",
  "dial_code": "358",
  "code": "AX"
  },
  {
  "name": "Albania",
  "dial_code": "355",
  "code": "AL"
  },
  {
  "name": "Algeria",
  "dial_code": "213",
  "code": "DZ"
  },
  {
  "name": "AmericanSamoa",
  "dial_code": "1684",
  "code": "AS"
  },
  {
  "name": "Andorra",
  "dial_code": "376",
  "code": "AD"
  },
  {
  "name": "Angola",
  "dial_code": "244",
  "code": "AO"
  },
  {
  "name": "Anguilla",
  "dial_code": "1264",
  "code": "AI"
  },
  {
  "name": "Antarctica",
  "dial_code": "672",
  "code": "AQ"
  },
  {
  "name": "Antigua and Barbuda",
  "dial_code": "1268",
  "code": "AG"
  },
  {
  "name": "Argentina",
  "dial_code": "54",
  "code": "AR"
  },
  {
  "name": "Armenia",
  "dial_code": "374",
  "code": "AM"
  },
  {
  "name": "Aruba",
  "dial_code": "297",
  "code": "AW"
  },
  {
  "name": "Australia",
  "dial_code": "61",
  "code": "AU"
  },
  {
  "name": "Austria",
  "dial_code": "43",
  "code": "AT"
  },
  {
  "name": "Azerbaijan",
  "dial_code": "994",
  "code": "AZ"
  },
  {
  "name": "Bahamas",
  "dial_code": "1242",
  "code": "BS"
  },
  {
  "name": "Bahrain",
  "dial_code": "973",
  "code": "BH"
  },
  {
  "name": "Bangladesh",
  "dial_code": "880",
  "code": "BD"
  },
  {
  "name": "Barbados",
  "dial_code": "1246",
  "code": "BB"
  },
  {
  "name": "Belarus",
  "dial_code": "375",
  "code": "BY"
  },
  {
  "name": "Belgium",
  "dial_code": "32",
  "code": "BE"
  },
  {
  "name": "Belize",
  "dial_code": "501",
  "code": "BZ"
  },
  {
  "name": "Benin",
  "dial_code": "229",
  "code": "BJ"
  },
  {
  "name": "Bermuda",
  "dial_code": "1441",
  "code": "BM"
  },
  {
  "name": "Bhutan",
  "dial_code": "975",
  "code": "BT"
  },
  {
  "name": "Bolivia, Plurinational State of",
  "dial_code": "591",
  "code": "BO"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dial_code": "387",
  "code": "BA"
  },
  {
  "name": "Botswana",
  "dial_code": "267",
  "code": "BW"
  },
  {
  "name": "Brazil",
  "dial_code": "55",
  "code": "BR"
  },
  {
  "name": "British Indian Ocean Territory",
  "dial_code": "246",
  "code": "IO"
  },
  {
  "name": "Brunei Darussalam",
  "dial_code": "673",
  "code": "BN"
  },
  {
  "name": "Bulgaria",
  "dial_code": "359",
  "code": "BG"
  },
  {
  "name": "Burkina Faso",
  "dial_code": "226",
  "code": "BF"
  },
  {
  "name": "Burundi",
  "dial_code": "257",
  "code": "BI"
  },
  {
  "name": "Cambodia",
  "dial_code": "855",
  "code": "KH"
  },
  {
  "name": "Cameroon",
  "dial_code": "237",
  "code": "CM"
  },
  {
  "name": "Canada",
  "dial_code": "1",
  "code": "CA"
  },
  {
  "name": "Cape Verde",
  "dial_code": "238",
  "code": "CV"
  },
  {
  "name": "Cayman Islands",
  "dial_code": " 345",
  "code": "KY"
  },
  {
  "name": "Central African Republic",
  "dial_code": "236",
  "code": "CF"
  },
  {
  "name": "Chad",
  "dial_code": "235",
  "code": "TD"
  },
  {
  "name": "Chile",
  "dial_code": "56",
  "code": "CL"
  },
  {
  "name": "China",
  "dial_code": "86",
  "code": "CN"
  },
  {
  "name": "Christmas Island",
  "dial_code": "61",
  "code": "CX"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dial_code": "61",
  "code": "CC"
  },
  {
  "name": "Colombia",
  "dial_code": "57",
  "code": "CO"
  },
  {
  "name": "Comoros",
  "dial_code": "269",
  "code": "KM"
  },
  {
  "name": "Congo",
  "dial_code": "242",
  "code": "CG"
  },
  {
  "name": "Congo, The Democratic Republic of the Congo",
  "dial_code": "243",
  "code": "CD"
  },
  {
  "name": "Cook Islands",
  "dial_code": "682",
  "code": "CK"
  },
  {
  "name": "Costa Rica",
  "dial_code": "506",
  "code": "CR"
  },
  {
  "name": "Cote d'Ivoire",
  "dial_code": "225",
  "code": "CI"
  },
  {
  "name": "Croatia",
  "dial_code": "385",
  "code": "HR"
  },
  {
  "name": "Cuba",
  "dial_code": "53",
  "code": "CU"
  },
  {
  "name": "Cyprus",
  "dial_code": "357",
  "code": "CY"
  },
  {
  "name": "Czech Republic",
  "dial_code": "420",
  "code": "CZ"
  },
  {
  "name": "Denmark",
  "dial_code": "45",
  "code": "DK"
  },
  {
  "name": "Djibouti",
  "dial_code": "253",
  "code": "DJ"
  },
  {
  "name": "Dominica",
  "dial_code": "1767",
  "code": "DM"
  },
  {
  "name": "Dominican Republic",
  "dial_code": "1849",
  "code": "DO"
  },
  {
  "name": "Ecuador",
  "dial_code": "593",
  "code": "EC"
  },
  {
  "name": "Egypt",
  "dial_code": "20",
  "code": "EG"
  },
  {
  "name": "El Salvador",
  "dial_code": "503",
  "code": "SV"
  },
  {
  "name": "Equatorial Guinea",
  "dial_code": "240",
  "code": "GQ"
  },
  {
  "name": "Eritrea",
  "dial_code": "291",
  "code": "ER"
  },
  {
  "name": "Estonia",
  "dial_code": "372",
  "code": "EE"
  },
  {
  "name": "Ethiopia",
  "dial_code": "251",
  "code": "ET"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dial_code": "500",
  "code": "FK"
  },
  {
  "name": "Faroe Islands",
  "dial_code": "298",
  "code": "FO"
  },
  {
  "name": "Fiji",
  "dial_code": "679",
  "code": "FJ"
  },
  {
  "name": "Finland",
  "dial_code": "358",
  "code": "FI"
  },
  {
  "name": "France",
  "dial_code": "33",
  "code": "FR"
  },
  {
  "name": "French Guiana",
  "dial_code": "594",
  "code": "GF"
  },
  {
  "name": "French Polynesia",
  "dial_code": "689",
  "code": "PF"
  },
  {
  "name": "Gabon",
  "dial_code": "241",
  "code": "GA"
  },
  {
  "name": "Gambia",
  "dial_code": "220",
  "code": "GM"
  },
  {
  "name": "Georgia",
  "dial_code": "995",
  "code": "GE"
  },
  {
  "name": "Germany",
  "dial_code": "49",
  "code": "DE"
  },
  {
  "name": "Ghana",
  "dial_code": "233",
  "code": "GH"
  },
  {
  "name": "Gibraltar",
  "dial_code": "350",
  "code": "GI"
  },
  {
  "name": "Greece",
  "dial_code": "30",
  "code": "GR"
  },
  {
  "name": "Greenland",
  "dial_code": "299",
  "code": "GL"
  },
  {
  "name": "Grenada",
  "dial_code": "1473",
  "code": "GD"
  },
  {
  "name": "Guadeloupe",
  "dial_code": "590",
  "code": "GP"
  },
  {
  "name": "Guam",
  "dial_code": "1671",
  "code": "GU"
  },
  {
  "name": "Guatemala",
  "dial_code": "502",
  "code": "GT"
  },
  {
  "name": "Guernsey",
  "dial_code": "44",
  "code": "GG"
  },
  {
  "name": "Guinea",
  "dial_code": "224",
  "code": "GN"
  },
  {
  "name": "Guinea-Bissau",
  "dial_code": "245",
  "code": "GW"
  },
  {
  "name": "Guyana",
  "dial_code": "595",
  "code": "GY"
  },
  {
  "name": "Haiti",
  "dial_code": "509",
  "code": "HT"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dial_code": "379",
  "code": "VA"
  },
  {
  "name": "Honduras",
  "dial_code": "504",
  "code": "HN"
  },
  {
  "name": "Hong Kong",
  "dial_code": "852",
  "code": "HK"
  },
  {
  "name": "Hungary",
  "dial_code": "36",
  "code": "HU"
  },
  {
  "name": "Iceland",
  "dial_code": "354",
  "code": "IS"
  },
  {
  "name": "India",
  "dial_code": "91",
  "code": "IN"
  },
  {
  "name": "Indonesia",
  "dial_code": "62",
  "code": "ID"
  },
  {
  "name": "Iran, Islamic Republic of Persian Gulf",
  "dial_code": "98",
  "code": "IR"
  },
  {
  "name": "Iraq",
  "dial_code": "964",
  "code": "IQ"
  },
  {
  "name": "Ireland",
  "dial_code": "353",
  "code": "IE"
  },
  {
  "name": "Isle of Man",
  "dial_code": "44",
  "code": "IM"
  },
  {
  "name": "Israel",
  "dial_code": "972",
  "code": "IL"
  },
  {
  "name": "Italy",
  "dial_code": "39",
  "code": "IT"
  },
  {
  "name": "Jamaica",
  "dial_code": "1876",
  "code": "JM"
  },
  {
  "name": "Japan",
  "dial_code": "81",
  "code": "JP"
  },
  {
  "name": "Jersey",
  "dial_code": "44",
  "code": "JE"
  },
  {
  "name": "Jordan",
  "dial_code": "962",
  "code": "JO"
  },
  {
  "name": "Kazakhstan",
  "dial_code": "77",
  "code": "KZ"
  },
  {
  "name": "Kenya",
  "dial_code": "254",
  "code": "KE"
  },
  {
  "name": "Kiribati",
  "dial_code": "686",
  "code": "KI"
  },
  {
  "name": "Korea, Democratic People's Republic of Korea",
  "dial_code": "850",
  "code": "KP"
  },
  {
  "name": "Korea, Republic of South Korea",
  "dial_code": "82",
  "code": "KR"
  },
  {
  "name": "Kuwait",
  "dial_code": "965",
  "code": "KW"
  },
  {
  "name": "Kyrgyzstan",
  "dial_code": "996",
  "code": "KG"
  },
  {
  "name": "Laos",
  "dial_code": "856",
  "code": "LA"
  },
  {
  "name": "Latvia",
  "dial_code": "371",
  "code": "LV"
  },
  {
  "name": "Lebanon",
  "dial_code": "961",
  "code": "LB"
  },
  {
  "name": "Lesotho",
  "dial_code": "266",
  "code": "LS"
  },
  {
  "name": "Liberia",
  "dial_code": "231",
  "code": "LR"
  },
  {
  "name": "Libyan Arab Jamahiriya",
  "dial_code": "218",
  "code": "LY"
  },
  {
  "name": "Liechtenstein",
  "dial_code": "423",
  "code": "LI"
  },
  {
  "name": "Lithuania",
  "dial_code": "370",
  "code": "LT"
  },
  {
  "name": "Luxembourg",
  "dial_code": "352",
  "code": "LU"
  },
  {
  "name": "Macao",
  "dial_code": "853",
  "code": "MO"
  },
  {
  "name": "Macedonia",
  "dial_code": "389",
  "code": "MK"
  },
  {
  "name": "Madagascar",
  "dial_code": "261",
  "code": "MG"
  },
  {
  "name": "Malawi",
  "dial_code": "265",
  "code": "MW"
  },
  {
  "name": "Malaysia",
  "dial_code": "60",
  "code": "MY"
  },
  {
  "name": "Maldives",
  "dial_code": "960",
  "code": "MV"
  },
  {
  "name": "Mali",
  "dial_code": "223",
  "code": "ML"
  },
  {
  "name": "Malta",
  "dial_code": "356",
  "code": "MT"
  },
  {
  "name": "Marshall Islands",
  "dial_code": "692",
  "code": "MH"
  },
  {
  "name": "Martinique",
  "dial_code": "596",
  "code": "MQ"
  },
  {
  "name": "Mauritania",
  "dial_code": "222",
  "code": "MR"
  },
  {
  "name": "Mauritius",
  "dial_code": "230",
  "code": "MU"
  },
  {
  "name": "Mayotte",
  "dial_code": "262",
  "code": "YT"
  },
  {
  "name": "Mexico",
  "dial_code": "52",
  "code": "MX"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dial_code": "691",
  "code": "FM"
  },
  {
  "name": "Moldova",
  "dial_code": "373",
  "code": "MD"
  },
  {
  "name": "Monaco",
  "dial_code": "377",
  "code": "MC"
  },
  {
  "name": "Mongolia",
  "dial_code": "976",
  "code": "MN"
  },
  {
  "name": "Montenegro",
  "dial_code": "382",
  "code": "ME"
  },
  {
  "name": "Montserrat",
  "dial_code": "1664",
  "code": "MS"
  },
  {
  "name": "Morocco",
  "dial_code": "212",
  "code": "MA"
  },
  {
  "name": "Mozambique",
  "dial_code": "258",
  "code": "MZ"
  },
  {
  "name": "Myanmar",
  "dial_code": "95",
  "code": "MM"
  },
  {
  "name": "Namibia",
  "dial_code": "264",
  "code": "NA"
  },
  {
  "name": "Nauru",
  "dial_code": "674",
  "code": "NR"
  },
  {
  "name": "Nepal",
  "dial_code": "977",
  "code": "NP"
  },
  {
  "name": "Netherlands",
  "dial_code": "31",
  "code": "NL"
  },
  {
  "name": "Netherlands Antilles",
  "dial_code": "599",
  "code": "AN"
  },
  {
  "name": "New Caledonia",
  "dial_code": "687",
  "code": "NC"
  },
  {
  "name": "New Zealand",
  "dial_code": "64",
  "code": "NZ"
  },
  {
  "name": "Nicaragua",
  "dial_code": "505",
  "code": "NI"
  },
  {
  "name": "Niger",
  "dial_code": "227",
  "code": "NE"
  },
  {
  "name": "Nigeria",
  "dial_code": "234",
  "code": "NG"
  },
  {
  "name": "Niue",
  "dial_code": "683",
  "code": "NU"
  },
  {
  "name": "Norfolk Island",
  "dial_code": "672",
  "code": "NF"
  },
  {
  "name": "Northern Mariana Islands",
  "dial_code": "1670",
  "code": "MP"
  },
  {
  "name": "Norway",
  "dial_code": "47",
  "code": "NO"
  },
  {
  "name": "Oman",
  "dial_code": "968",
  "code": "OM"
  },
  {
  "name": "Pakistan",
  "dial_code": "92",
  "code": "PK"
  },
  {
  "name": "Palau",
  "dial_code": "680",
  "code": "PW"
  },
  {
  "name": "Palestinian Territory, Occupied",
  "dial_code": "970",
  "code": "PS"
  },
  {
  "name": "Panama",
  "dial_code": "507",
  "code": "PA"
  },
  {
  "name": "Papua New Guinea",
  "dial_code": "675",
  "code": "PG"
  },
  {
  "name": "Paraguay",
  "dial_code": "595",
  "code": "PY"
  },
  {
  "name": "Peru",
  "dial_code": "51",
  "code": "PE"
  },
  {
  "name": "Philippines",
  "dial_code": "63",
  "code": "PH"
  },
  {
  "name": "Pitcairn",
  "dial_code": "872",
  "code": "PN"
  },
  {
  "name": "Poland",
  "dial_code": "48",
  "code": "PL"
  },
  {
  "name": "Portugal",
  "dial_code": "351",
  "code": "PT"
  },
  {
  "name": "Puerto Rico",
  "dial_code": "1939",
  "code": "PR"
  },
  {
  "name": "Qatar",
  "dial_code": "974",
  "code": "QA"
  },
  {
  "name": "Romania",
  "dial_code": "40",
  "code": "RO"
  },
  {
  "name": "Russia",
  "dial_code": "7",
  "code": "RU"
  },
  {
  "name": "Rwanda",
  "dial_code": "250",
  "code": "RW"
  },
  {
  "name": "Reunion",
  "dial_code": "262",
  "code": "RE"
  },
  {
  "name": "Saint Barthelemy",
  "dial_code": "590",
  "code": "BL"
  },
  {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "dial_code": "290",
  "code": "SH"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dial_code": "1869",
  "code": "KN"
  },
  {
  "name": "Saint Lucia",
  "dial_code": "1758",
  "code": "LC"
  },
  {
  "name": "Saint Martin",
  "dial_code": "590",
  "code": "MF"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dial_code": "508",
  "code": "PM"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dial_code": "1784",
  "code": "VC"
  },
  {
  "name": "Samoa",
  "dial_code": "685",
  "code": "WS"
  },
  {
  "name": "San Marino",
  "dial_code": "378",
  "code": "SM"
  },
  {
  "name": "Sao Tome and Principe",
  "dial_code": "239",
  "code": "ST"
  },
  {
  "name": "Saudi Arabia",
  "dial_code": "966",
  "code": "SA"
  },
  {
  "name": "Senegal",
  "dial_code": "221",
  "code": "SN"
  },
  {
  "name": "Serbia",
  "dial_code": "381",
  "code": "RS"
  },
  {
  "name": "Seychelles",
  "dial_code": "248",
  "code": "SC"
  },
  {
  "name": "Sierra Leone",
  "dial_code": "232",
  "code": "SL"
  },
  {
  "name": "Singapore",
  "dial_code": "65",
  "code": "SG"
  },
  {
  "name": "Slovakia",
  "dial_code": "421",
  "code": "SK"
  },
  {
  "name": "Slovenia",
  "dial_code": "386",
  "code": "SI"
  },
  {
  "name": "Solomon Islands",
  "dial_code": "677",
  "code": "SB"
  },
  {
  "name": "Somalia",
  "dial_code": "252",
  "code": "SO"
  },
  {
  "name": "South Africa",
  "dial_code": "27",
  "code": "ZA"
  },
  {
  "name": "South Sudan",
  "dial_code": "211",
  "code": "SS"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dial_code": "500",
  "code": "GS"
  },
  {
  "name": "Spain",
  "dial_code": "34",
  "code": "ES"
  },
  {
  "name": "Sri Lanka",
  "dial_code": "94",
  "code": "LK"
  },
  {
  "name": "Sudan",
  "dial_code": "249",
  "code": "SD"
  },
  {
  "name": "Suriname",
  "dial_code": "597",
  "code": "SR"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dial_code": "47",
  "code": "SJ"
  },
  {
  "name": "Swaziland",
  "dial_code": "268",
  "code": "SZ"
  },
  {
  "name": "Sweden",
  "dial_code": "46",
  "code": "SE"
  },
  {
  "name": "Switzerland",
  "dial_code": "41",
  "code": "CH"
  },
  {
  "name": "Syrian Arab Republic",
  "dial_code": "963",
  "code": "SY"
  },
  {
  "name": "Taiwan",
  "dial_code": "886",
  "code": "TW"
  },
  {
  "name": "Tajikistan",
  "dial_code": "992",
  "code": "TJ"
  },
  {
  "name": "Tanzania, United Republic of Tanzania",
  "dial_code": "255",
  "code": "TZ"
  },
  {
  "name": "Thailand",
  "dial_code": "66",
  "code": "TH"
  },
  {
  "name": "Timor-Leste",
  "dial_code": "670",
  "code": "TL"
  },
  {
  "name": "Togo",
  "dial_code": "228",
  "code": "TG"
  },
  {
  "name": "Tokelau",
  "dial_code": "690",
  "code": "TK"
  },
  {
  "name": "Tonga",
  "dial_code": "676",
  "code": "TO"
  },
  {
  "name": "Trinidad and Tobago",
  "dial_code": "1868",
  "code": "TT"
  },
  {
  "name": "Tunisia",
  "dial_code": "216",
  "code": "TN"
  },
  {
  "name": "Turkey",
  "dial_code": "90",
  "code": "TR"
  },
  {
  "name": "Turkmenistan",
  "dial_code": "993",
  "code": "TM"
  },
  {
  "name": "Turks and Caicos Islands",
  "dial_code": "1649",
  "code": "TC"
  },
  {
  "name": "Tuvalu",
  "dial_code": "688",
  "code": "TV"
  },
  {
  "name": "Uganda",
  "dial_code": "256",
  "code": "UG"
  },
  {
  "name": "Ukraine",
  "dial_code": "380",
  "code": "UA"
  },
  {
  "name": "United Arab Emirates",
  "dial_code": "971",
  "code": "AE"
  },
  {
  "name": "United Kingdom",
  "dial_code": "44",
  "code": "GB"
  },
  {
  "name": "United States",
  "dial_code": "1",
  "code": "US"
  },
  {
  "name": "Uruguay",
  "dial_code": "598",
  "code": "UY"
  },
  {
  "name": "Uzbekistan",
  "dial_code": "998",
  "code": "UZ"
  },
  {
  "name": "Vanuatu",
  "dial_code": "678",
  "code": "VU"
  },
  {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "dial_code": "58",
  "code": "VE"
  },
  {
  "name": "Vietnam",
  "dial_code": "84",
  "code": "VN"
  },
  {
  "name": "Virgin Islands, British",
  "dial_code": "1284",
  "code": "VG"
  },
  {
  "name": "Virgin Islands, U.S.",
  "dial_code": "1340",
  "code": "VI"
  },
  {
  "name": "Wallis and Futuna",
  "dial_code": "681",
  "code": "WF"
  },
  {
  "name": "Yemen",
  "dial_code": "967",
  "code": "YE"
  },
  {
  "name": "Zambia",
  "dial_code": "260",
  "code": "ZM"
  },
  {
  "name": "Zimbabwe",
  "dial_code": "263",
  "code": "ZW"
  }
  ]


  export const pathInfo = {
    '/': {
      title: 'Welcome to the Capital Development Authority | Official Website',
      description: "Discover the Capital Development Authority (CDA), dedicated to serving Islamabad's development needs. Explore our initiatives, services, and projects for a thriving capital city."
    },
    '/board-members': {
      title: 'Get Acquainted with Our Esteemed Board Members | CDA',
      description: 'Discover the dedicated individuals shaping our organizations direction. Learn more about our esteemed board members and their invaluable contributions to the CDA.'
    },
    '/all-honourable-chairman': {
      title: 'Meet Our Distinguished Honourable Chairman | CDA',
      description: 'explore the profiles of our distinguished Chairman and learn about their visionary leadership and commitment to excellence in steering the CDA towards its goals.'
    },
    '/board-meetings': {
      title: 'Dive Into Our Insightful Board Meetings | CDA',
      description: 'Stay updated on our board meetings, agendas, and decisions. Witness our commitment to transparency and progress. Join us in shaping the future.'
    },
    '/cda-training-academy': {
      title: 'Experience  Our Training Academy | Unlock Your Potential | CDA',
      description: 'Discover the comprehensive training programs offered by CDA Training Academy. Elevate your skills and knowledge with our expert-led courses'
    },
    '/cda-history': {
      title: 'Uncover the Vibrant History of CDA | Embrace Our Legacy |CDA',
      description: 'Explore the fascinating journey and milestones of the CDA through its history page. Learn about our heritage and the evolution of our organization.'
    },
    '/career': {
      title: 'Embark on a Journey of Career Advancement with Us | CDA',
      description: 'Discover rewarding career opportunities at CDA. Join a dynamic team dedicated to making a positive impact in our community. Explore now!'
    },
    '/about-islamabad': {
      title: 'Discover Islamabad: Your Comprehensive Guide| CDA',
      description: 'Explore the beauty, culture, and attractions of Islamabad, curated by CDA. Learn about the capital citys history, landmarks, and more'
    },
    '/chairman-cda-message': {
      title: 'Message from the Chairman: Leading Development Authority | CDA',
      description: "Read the latest message from the Chairman of CDA. Stay informed about the vision, goals, and initiatives driving Islamabad's development"
    },
    '/hospitals': {
      title: 'Find the Best Healthcare Facilities in Islamabad | CDA',
      description: "Locate hospitals in Islamabad with ease through CDA's dedicated page. Access information on medical facilities, services, and more."
    },
    '/hospitality': {
      title: 'Discover Hospitality Services in Islamabad | CDA',
      description: "Explore the hospitality offerings in Islamabad with CDA's dedicated page. Find information on hotels, restaurants, and tourism services."
    },
    '/universities-of-islamabad': {
      title: 'Explore All Universities in Islamabad | CDA',
      description: "Discover the diverse array of universities in Islamabad with CDA's dedicated page. Learn about educational opportunities, programs, and more"
    },
    '/destinations-in-islamabad': {
      title: "Discover Islamabad's Top Destinations | CDA",
      description: "Explore the must-visit destinations in Islamabad with CDA's dedicated page. From iconic landmarks to hidden gems, find your next adventure here."
    },
    '/adventures': {
      title: 'Embark on Exciting Adventures in Islamabad | CDA',
      description: "Explore thrilling adventures in Islamabad with CDA's dedicated page. From hiking trails to cultural experiences, discover your next adrenaline rush here."
    },
    '/cda-hospital': {
      title: 'Explore CDA Hospital Services | CDA',
      description: "Discover comprehensive healthcare services provided by CDA Hospital. Learn about our medical specialties, facilities, and patient care approach."
    },
    '/cda-model-school': {
      title: 'Discover Excellence at CDA Model School | CDA',
      description: "Experience quality education and holistic development at CDA Model School. Explore our academic programs, extracurricular activities, and nurturing environment."
    },
    '/complaint-management-system': {
      title: 'Efficient Complaint Management System | CDA',
      description: "Explore our streamlined complaint management system, ensuring swift resolution of grievances. Your feedback matters to us."
    },
    '/procedures': {
      title: "Discover CDA's Standard Operating Procedures | CDA",
      description: "Learn about the standard operating procedures followed by CDA for efficient governance and service delivery."
    },
    '/cda-ordinance-1960': {
      title: 'Understanding the CDA Ordinance 1960 | CDA',
      description: "Delve into the CDA Ordinance 1960, the legal framework guiding the Capital Development Authority's operations and responsibilities."
    },
    '/by-laws-regulations': {
      title: "Explore CDA's By-Laws & Regulations | CDA",
      description: 'Discover the by-laws and regulations enforced by CDA, ensuring orderly development and compliance within Islamabad'
    },
    '/gallery': {
      title: "Explore CDA's Gallery | CDA",
      description: "Browse through our gallery showcasing the projects, events, and achievements of the Capital Development Authority."
    },
    '/auction-notices': {
      title: 'Stay Informed with CDA Auction Notices | CDA',
      description: 'Find the latest auction notices issued by CDA, providing opportunities for investment and development in Islamabad.'
    },
    '/housing-societies': {
      title: 'Learn About Housing Societies in Islamabad | CDA',
      description: 'Discover information about housing societies in Islamabad, including regulations, developments, and investment opportunities.'
    },


    '/housing-societies/:id': {
      title: 'Discover All Housing Societies in Islamabad | CDA',
      description: 'Explore the diverse range of housing societies in Islamabad regulated by CDA. Find information on residential options, amenities, and investment opportunities'
    },
    '/apply-now': {
      title: 'Apply Now for Opportunities with CDA | CDA',
      description: "Explore career and procurement opportunities with CDA and apply to be a part of Islamabad's development journey."
    },
    '/housing-societies/commercial-private/:id': {
      title: 'Explore Commercial and Private Housing Societies | CDA',
      description: 'Discover commercial and private housing societies regulated by CDA. Learn about investment opportunities and development projects in Islamabad'
    },

    '/housing-societies/commercial-cda/:id': {
      title: 'Explore CDA-Regulated Commercial Housing Societies | CDA',
      description: "Discover commercial housing societies regulated by CDA. Explore investment opportunities and development projects in Islamabad's prime locations."
    },

    '/illegal-housing-schemes': {
      title: 'Awareness on Illegal Housing Schemes | CDA ',
      description: 'Gain insights into illegal housing schemes to protect yourself from fraudulent activities. Stay informed with CDA'
    },

    '/balloting-sector-c16-i15': {
      title: 'Balloting Sector C16 I15 Information | CDA',
      description: "Find information on balloting for Sector C16 I15 plots. Stay updated with CDA's latest developments."
    },
    '/open-auction-of-plots': {
      title: "Participate in CDA's Open Auction of Plots | CDA ",
      description: "Get involved in CDA's open auction of plots and seize the opportunity to invest in prime real estate in Islamabad."
    },
    '/public-notice': {
      title: "Stay Informed with CDA's Public Notices | CDA",
      description: "Stay informed about important updates, announcements, and notifications from CDA through our public notices."
    },
    '/auction-notices': {
      title: 'Stay Informed with CDA Auction Notices | CDA',
      description: "Explore the latest auction notices from CDA. Find opportunities for investment and development in Islamabad's real estate market."
    },
    '/news-releases': {
      title: 'Read the Latest News Releases from CDA | CDA',
      description: 'Stay up-to-date with the latest news, events, and announcements from CDA through our official news releases.'
    },
    '/news-releases-article': {
      title: 'Stay Updated with CDA News Releases | CDA',
      description: "Explore the latest news articles and press releases from CDA. Stay informed about developments, initiatives, and announcements shaping Islamabad's future."
    },
    '/forms-and-applications': {
      title: 'Access CDA Forms and Applications | CDA',
      description: 'Download the necessary forms and applications for various services and procedures offered by CDA.'
    },
    '/careers-and-jobs': {
      title: 'Explore Career Opportunities with CDA | CDA',
      description: 'Discover rewarding career opportunities and job vacancies with CDA, contributing to the development of Islamabad.'
    },
    '/tenders': {
      title: 'Find Tenders and Procurement Opportunities | CDA',
      description: 'Explore tender opportunities and procurement notices from CDA, inviting bids for various projects and services.'
    },
    '/procurement-plan': {
      title: "View CDA's Procurement Plans | CDA",
      description: "Access CDA's procurement plans outlining upcoming acquisitions and procurement activities for efficient project execution"
    },
    '/cda-budget': {
      title: "Explore CDA's Budget Allocation | CDA",
      description: "Gain insights into CDA's budget allocation and financial planning, ensuring transparency and accountability in resource management."
    },
    '/cda-projects': {
      title: "Discover CDA's Ongoing Projects | CDA ",
      description: "Explore the diverse range of ongoing projects undertaken by CDA, contributing to the development of Islamabad."
    },


    '/galleryImages': {
      title: "Browse Through CDA's Gallery Images | CDA",
      description: "Discover a visual journey of CDA's projects, initiatives, and events through our extensive gallery of images."
    },
    '/faqs': {
      title: 'Find Answers to Your Questions with CDA FAQs | CDA',
      description: "Access frequently asked questions (FAQs) to get answers to common queries related to CDA's services, policies, and procedures."
    },
    '/chairman-secretariat': {
      title: 'Learn About CDA Chairman Secretariat | CDA',
      description: "Discover the role and functions of CDA Chairman Secretariat, serving as the administrative hub for the Chairman's office."
    },
    '/administration-wing': {
      title: "Explore CDA Administration Wing | CDA",
      description: "Learn about the administrative structure and functions of CDA's Administration Wing, ensuring efficient governance and management."
    },
    '/estate-wing': {
      title: 'Discover CDA Estate Wing | CDA',
      description: "Explore the responsibilities and activities of CDA's Estate Wing, overseeing land management and property affairs in Islamabad."
    },
    '/engineering-wing': {
      title: 'Explore CDA Engineering Wing | CDA',
      description: "Learn about the engineering expertise and infrastructure development initiatives led by CDA's Engineering Wing in Islamabad."
    },
    '/office-of-deputy-financial-advisor': {
      title: "Learn About CDA's Office of Deputy Financial Advisor | CDA",
      description: ''
    },
    '/planning-and-design-wing': {
      title: 'Explore CDA Planning and Design Wing | CDA',
      description: "Learn about the urban planning and design strategies implemented by CDA's Planning and Design Wing for sustainable development in Islamabad."
    },
    '/environmental-protection-cell': {
      title: 'Discover CDA Environmental Protection Cell | CDA',
      description: "Explore the initiatives and efforts of CDA's Environmental Protection Cell in preserving and enhancing Islamabad's environment."
    },
    '/tech-wing': {
      title: "Discover Cutting-Edge Innovation with CDA's Technology Wing | CDA",
      description: "Discover the innovative initiatives and technological advancements led by CDA's Technology Wing. Learn about our role in shaping Islamabad's digital landscape."
    },
    '/contact-us': {
      title: 'Connect and Reach Out to Us Today  | CDA',
      description: "Have questions or need assistance? Contact CDA for inquiries, feedback, and support. We're here to help you with all your concerns regarding Islamabad's development."
    }
  };


